@import '~styles/vars';

.clePipelineItem {
  width: 120px;
  .cellLineName {
    @include font-label-small-bold;
    color: $grey-dark;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .plateType {
    @include font-small-info;
    color: $grey-dark;
    margin-top: 4px;
  }

  &.hasAlert {
    .alertIcon {
      fill: $warning;
      height: 12px;
      width: 12px;
      margin-left: -5px;
    }
    .cellLineName {
      color: $warning;
    }
    .plateType {
      color: $warning;
    }
  }
}

.clickable {
  cursor: pointer;
}
