@import '~styles/vars';

.target {
  display: inline-block;
}

.popover {
  padding: 30px;
  width: 300px;
}

.button {
  margin-top: 15px;
}

.loadingMessage {
  margin-top: 15px;
}
