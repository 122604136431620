@import '~styles/vars';

.select {
  @include font-subtext;
  padding: 6px 4px 6px 8px;
  border: 1px $grey-light solid;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.noBorder {
    border: none;
  }

  &.disabled {
    &::after {
      content: '';
      background-color: rgba(0, 0, 0, 0.05);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    cursor: inherit;
  }

  &:hover:not(.disabled) {
    background-color: $off-white;
  }

  .icon {
    width: 20px;
    height: 20px;
    fill: $grey-medium;
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .selectedName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;

    &.isText {
      height: 20px;
    }

    .placeholder {
      color: $grey-light;
    }

    .noneTriggerText {
      color: $grey-medium;
      font-style: italic;
    }
  }

  &.disabled {
    .selectedName {
      color: $grey-light;
    }
  }
}

.label {
  @include font-label;
  color: $grey-medium;
  margin-bottom: 5px;
}

.option {
  padding: 6px 10px;
  cursor: pointer;
  @include font-subtext;

  &:hover {
    background-color: $off-white;
  }

  &.active {
    background-color: $grey-lightest;
  }

  &.noneOption {
    color: $grey-medium;
    font-style: italic;
  }
}

.noResults {
  padding: 6px 10px;
  color: $grey-medium;
}

.popover {
  :global(.bp4-menu) {
    max-height: 250px;
    overflow: auto;
    padding: 5px 0;
    max-width: none;
  }

  &.noPadding {
    :global(.bp4-popover-content) {
      padding: 0;
    }
  }
}
