@import '~styles/vars';

.container {
    margin: 1em;
    padding: 1em;
    align-self: stretch;
    border: 1px solid $grey-medium;
    color: $off-white;
    border-radius: 8px;
}

.group {
    margin-bottom: 1em;
}

.label {
    @include font-label-bold;
    text-transform: uppercase;
    color: $grey-lightest;
}

.value {
    @include font-subtext;

    a {
        color: $accent-bg;
        text-decoration: underline;
    }
}

.prod {
    background: $error;
    color: white;
    padding: 0 2px;
    border-radius: 3px;
    font-weight: bold;
    text-transform: uppercase;
}

.hideButton {
    @include font-label-small-bold;
    color: $accent;
    float: right;
}
