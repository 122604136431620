@import '~styles/vars';

.wellSelect {
  margin-top: 15px;
}
.trigger {
  width: 350px;
}
.popover {
  width: 350px;
}
.labwareSelect {
  margin-top: 15px;
}
