@import '~styles/vars';

.requiresUserAttentionDialog {
  width: 1200px;

  .routineList {
    max-height: 400px;
  }

  .button {
    margin-right: 20px;
  }

  .actionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .notification {
    margin-bottom: 5px;
  }
}
