@import '~styles/vars';

.futureRoutineScheduleForForm {
  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 5px;
  }
  .dateInput {
    width: 100%;
  }

  .shouldQueueAutomatically {
    @include font-subtext;
    color: $grey-dark;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-flex;
  }
}
