@import '~styles/vars';

.workflowsInstanceHeader {
  display: flex;

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .icon {
      fill: $grey-dark;
      width: 20px;
      height: 20px;
      margin-top: 1px;
      margin-right: 7px;
    }

    .title {
      display: flex;
      align-items: center;
      @include font-subtitle;
      color: $grey-dark;

      .menu {
        height: 24px;
        margin-left: 5px;
      }
    }
  }
}
