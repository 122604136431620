@import '~styles/vars';

.label {
  @include font-label;
  color: $grey-medium;
  margin-bottom: 5px;
}

.input {
  @include font-subtext;
  padding: 6px 8px;
  border: 1px $grey-light solid;

  &::placeholder {
    color: $grey-light;
  }

  &.disabled {
    color: $grey-light;
    background-color: $off-white;
  }
}

.errorMessage {
  margin-top: 4px;
}
