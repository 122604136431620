@import "~styles/vars";

.linkLotsDataTable {
  display: flex;
  flex-direction: column;
  .table {
    flex: 1 1 0;
    min-height: 0;
  }
}
