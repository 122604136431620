@import '~styles/vars.scss';

.buttonPlayground {
  .contents {
    margin: 20px 80px;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 300px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .label {
        @include font-subtext;
        color: $grey-medium;
        margin-bottom: 10px;
      }
    }

    .button {
      margin-top: 10px;
    }
  }
}
