@import '~styles/vars';

.slack {
  .title {
    @include font-text;
  }

  .structuredDataForm {
    width: 300px;
  }

  .inputs {
    width: 300px;
  }

  .row {
    margin: 10px 0;
  }

  .button {
    margin-top: 10px;
    width: 300px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
  }
}
