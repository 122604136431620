@import '~styles/vars';

.notification {
  &.mini {
    .inner {
      padding: 10px 15px;
    }
  }

  .inner {
    @include font-subtext;
    padding: 20px 30px;
    display: flex;
    align-items: flex-start;

    .message {
      flex: 1 1 0;
      min-width: 0;
      margin-top: 1px;
      word-break: break-word;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .closeIcon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-top: 2px;
    fill: $grey-medium;
    cursor: pointer;

    &:hover {
      fill: $grey-light;
    }
  }

  &.warning {
    .inner {
      background-color: $warning-bg;
      border: 1px solid $warning;
    }

    .icon {
      stroke: $warning;
    }
  }

  &.error {
    .inner {
      background-color: $error-bg;
      border: 1px solid $error;
    }

    .icon {
      stroke: $error;
    }
  }

  &.success {
    .inner {
      background-color: $success-bg;
      border: 1px solid $success;
    }

    .icon {
      fill: $success;
    }
  }

  &.accent {
    .inner {
      background-color: $accent-bg;
      border: 1px solid $accent;
    }

    .icon {
      fill: $accent;
    }
  }

  .contactUs {
    @include font-text-tiny;
    color: $grey-medium;
    text-align: center;
    margin: 10px auto 0;

    .link {
      text-decoration: underline;
    }
  }
}
