@import '~styles/vars';

.trigger {
  width: 350px;
  &.narrow {
    width: 250px;
  }
}

.popover {
  width: 350px;

  &.narrow {
    width: 250px;
  }
}
