@import '~styles/vars.scss';

.uiPlayground {
  width: 1200px;
  margin: 0 auto;
  height: 100vh;

  .title {
    @include font-title;
    padding: 20px 80px 10px;
  }

  .tabPanel {
    height: 100%;
  }

  .tabs {
    margin: 0 80px;
  }
}
