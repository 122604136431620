@import '~styles/vars';

.link {
  &.primary {
    color: $accent;

    &:hover {
      color: lighten($accent, 20%);
    }
  }

  &.error {
    color: $error;

    &:hover {
      color: lighten($error, 20%);
    }
  }
}
