@import '~styles/vars';

.item {
  display: flex;
  align-items: center;

  &.withViz {
    padding: 5px;
  }

  .viz {
    flex-shrink: 0;
    margin-right: 15px;
  }

  .processItemWithLocation {
    flex: 1 1 0;
    min-width: 0;

    .name {
      @include font-subtext;
      margin-bottom: 1px;
    }

    .info {
      display: flex;
      align-items: center;
      color: $grey-medium;
      @include font-label;
      flex: 1 1 0;
      min-width: 0;
    }
  }
}
