@import '~styles/vars';

.dataman70InstrumentPage {
  .realTimeState {
    margin-top: 40px;

    .label {
      @include font-label;
      margin-top: 20px;
      margin-bottom: 5px;
      color: $grey-medium;
    }

    .readerState {
      @include font-label-bold;
      color: $error;

      &.on {
        color: $success;
      }
    }
  }
}
