@import '~styles/vars';

.editJobDialog {
  width: 500px;

  .container {
    margin-top: 25px;
  }

  .footer {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .input {
    width: 300px;
  }

  .title {
    @include font-section-title;
    margin-bottom: 10px;
  }

  .advancedOptionsLink {
    @include font-text-tiny;
    margin-top: 20px;
    color: $grey-light;
    display: block;
  }
}
