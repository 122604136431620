@import '~styles/vars';

.staticStorageInstrumentPage {
  height: 100%;

  .genericInstrumentPage {
    height: 100%;
  }

  .storageViz {
    margin-top: 25px;
    flex: 1 1 0;
    overflow: auto;
  }

  .controlBox {
    margin-top: 40px;
  }

  .bodyContents {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 34px;
  }

  .processItemTable {
    margin-top: 10px;
  }
}
