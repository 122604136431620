@import '~styles/vars';

.popoverTrigger {
  cursor: pointer;
  @include font-text-tiny;
  color: $grey-medium;
  text-decoration: underline dashed $grey-medium;
}

.value {
  color: $grey-dark;
  @include font-subtext-bold;
}
