@import "~styles/vars";

.actionContainer {
  max-width: 840px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bigMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  height: 100px;
  color: $grey-medium;
}
