@import '~styles/vars';

.noParametersMessage {
  @include font-small-info;
  color: $grey-light;
  margin: 10px 0;
}

.inputs {
  width: 350px;
}
