@import '~styles/vars';

.inhecoScilaInstrumentPage {
  height: 100%;

  .genericInstrumentPage {
    height: 100%;
  }

  .transferStationViz {
    margin-top: 25px;
    flex: 1 1 0;
    overflow: auto;
  }

  .bodyContents {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 34px;

    .processItemTable {
      flex: 1 1 0;
      overflow: auto;
    }
  }

  .doorStatusOpened {
    color: $accent;
    font-weight: bold;
  }

  .doorStatusClosed {
    color: $grey-medium;
  }
}
