@import '~styles/vars';

.leftNav {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  width: $left-nav-width;
  align-items: flex-start;
  flex-shrink: 0;
  height: 100%;

  .logoContainer {
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 20px 10px;

    .logo {
      width: 125px;
      height: 30px;
    }
  }

  .navLinkIcon {
    stroke: $grey-light;
    height: 20px;
    width: 20px;
  }

  .navLinkIconFill {
    fill: $grey-light;
    opacity: 0.8;
    height: 20px;
    width: 20px;
  }

  .navLinkContainer {
    padding: 8px 18px;
    display: flex;
    outline: none;
    align-items: center;
    width: 100%;

    .iconContainer {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .label {
      @include font-text-tiny;
      color: $grey-light;
      margin-left: 8px;
    }

    &.active,
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);

      .navLink {
        stroke: white;
      }
    }
  }

  .userProfileContainer {
    padding: 6px 12px;
    display: block;
    cursor: pointer;
  }

  .userProfile {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .fill {
    flex: 1 1 0;
  }

  .app {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 14px 20px;

    &.active,
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    .appName {
      @include font-text-tiny;
      margin-left: 8px;
      color: $grey-light;
    }
  }
}

.mobile {
  display: none;
  background-color: $primary;

  .mobileNav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    .icon {
      fill: $grey-medium;
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      left: 10px;
    }

    .logo {
      height: 24px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: $full-screen-max-width) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
