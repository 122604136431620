@import '~styles/vars';

@mixin size($factor) {
  margin-bottom: 4px * $factor;
}

.transferStationViz {
  .transferStation {
    @include size(2.5);

    &.condensed {
      @include size(1);

      &.mini {
        @include size(0.7);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .transferStationLabel {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 2px;
  }
}
