@import "~styles/vars";

.validatePanelFooter {
  border-top: 1px solid $grey-lightest;

  .caption {
    margin-top: 15px;
    max-width: 450px;
  }

  .button {
    margin-top: 8px;
    margin-bottom: 20px;
    width: 350px;
  }
}
