@import "~styles/vars";

.validatePanel {
  height: 100%;
  display: flex;
  flex-direction: column;

  .body {
    overflow: auto;
    flex: 1 1 0;
    display: flex;
    min-height: 0;
  }

  .form {
    width: 350px;
    margin-right: 100px;
  }

  .rightPane {
    flex: 1 1 0;
    min-width: 0;

    .inlineDialog {
      min-height: 300px;
      height: calc(100% - 40px);
    }
  }
}
