@import '~styles/vars';

.app {
  display: flex;
  height: 100vh;

  .main {
    flex: 1 1 0;
    min-width: 0;
    overflow: auto;
  }
}

@media screen and (max-width: $full-screen-max-width) {
  .app {
    flex-direction: column;
  }
}

.fullPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 5em 0 0;

  p {
    margin: 2em 0;
  }
}

.slowLoadingIndicator {
  margin-bottom: 1.5em;
  opacity: 0;
  filter: grayscale(1);
  animation: 1.5s cubic-bezier(0.88, 0.01, 0.47, 1.55) 2s slowLoadingIndicatorAnimation;
  animation-fill-mode: forwards;
}

.slowLoadingMessage {
  margin-left: 6px; // Visual centering
  @include font-label;
  color: $grey-medium;
  opacity: 0;
  animation: 3s linear 5s slowLoadingMessageAnimation;
  animation-fill-mode: forwards;
}

@keyframes slowLoadingIndicatorAnimation {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 0.5;
    transform: scale(1);
  }
}

@keyframes slowLoadingMessageAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fullPageErrorContainer {
  margin-top: 2em;
}
