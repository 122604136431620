@import '~styles/vars';

.routineDefinitionDialog {
  width: 600px;

  .title {
    @include font-section-title;
  }

  .fileName {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 15px;
  }

  .container {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-medium;
  }

  .editorContainer {
    height: 300px;
    overflow: auto;
    padding: 10px;
    border: 1px solid $grey-medium;

    .editor {
      font-family: 'Fira code', 'Fira Mono', monospace;
      font-size: 12px;
    }
  }
}
