@import '~styles/vars';

.uuidInput {
  width: 100%;
}
.trigger {
  width: 350px;
}
.popover {
  width: 350px;
}
.labwareSelect {
  margin-top: 15px;
}
.infoNotification {
  margin-bottom: 5px;
}
