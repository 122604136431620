@import '~styles/vars';

.container {
    margin: 2em;
}

.title {
    @include font-title;
    margin-bottom: 1em;
}
