@import '~styles/vars.scss';

.integrationsDashboard {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .title {
    @include font-title;
    padding: 20px 80px 10px;
  }

  .integrations {
    margin-top: 20px;
    border-top: 1px solid $grey-lightest;
  }

  .integration {
    display: flex;
    padding: 20px 80px;
    align-items: center;

    .logo {
      width: 100px;
    }

    .text {
      margin-left: 12px;
    }

    .name {
      @include font-section-title;
      display: flex;
      align-items: center;
    }

    .pill {
      margin-left: 8px;
    }

    .description {
      color: $grey-medium;
      margin-top: 2px;
    }

    .controls {
      margin-top: 12px;

      .button {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    margin-bottom: 40px;
    color: $grey-medium;
  }
}
