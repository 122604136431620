@import '~styles/vars';

.gridTable {
  outline: none;
  box-shadow: none;

  :global(.bp4-table-column-name-text) {
    @include font-label;
    color: $grey-medium;
    margin-top: 8px;
    pointer-events: all;
  }

  :global(.bp4-table-header) {
    box-shadow: none;
  }

  :global(.bp4-table-quadrant) {
    background: $white;
  }

  :global(.bp4-table-column-headers) {
    background: $white;
  }

  .cell {
    @include font-text-tiny;
    color: $grey-dark;
    display: flex;
    align-items: center;
    border-top: 1px solid $grey-lightest;
    box-shadow: none;
  }

  .noDataMessage {
    @include font-subtext;
    color: $grey-medium;
  }
}
