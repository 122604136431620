@import "~styles/vars";

.uploadMetadataStepPreview {
  display: flex;
  flex-direction: column;
  height: 100%;
  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-bottom: 5px;
  }

  .info {
    @include font-info;
    color: $grey-dark;
  }

  .errorCount {
    @include font-info;
    color: $grey-dark;
  }

  .platePreview {
    margin: 20px 0;
  }

  .topSection {
    margin-bottom: 10px;
  }

  .contents {
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
  }

  .parseDataErrorTable {
    padding-bottom: 10px;
    padding-right: 25px;
  }
}
