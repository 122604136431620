@import '~styles/vars';

.sectionHeader {
  color: $grey-dark;
  @include font-section-title;
}

.formContainer {
  margin-bottom: 30px;
}
