@import "~styles/vars";

.submitStep {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex: 1 1 0;
  min-height: 0;

  .successNotification {
    margin-bottom: 10px;
  }

  .title {
    @include font-section-title;
    color: $grey-dark;
  }

  .contents {
    display: flex;
    height: 100%;
  }

  .dataTableColumn {
    width: 550px;
    margin-right: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .dataTable {
    flex: 1 1 0;
    min-width: 0;
  }

  .platePreviewColumn {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }

  .platePreview {
    flex: 1 1 0;
    min-width: 0;
    overflow: auto;
  }
}

.submitError {
  margin-top: 30px;
  width: 400px;
}

.loadingMessageBox {
  width: 400px;
  padding: 30px 0;
  margin-top: 30px;
}
