@import "~styles/vars";

.presentationalScheduleOperatorAction {
  overflow: auto;
  flex: 1 1 0;
  min-height: 0;
  display: flex;
  flex-direction: column;

  .appHeader {
    margin-top: 20px;
  }

  .contents {
    margin-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .body {
      flex: 1 1 0;
      min-height: 0;
      overflow: auto;
    }

    .footer {
      border-top: 1px solid $grey-lightest;

      .button {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 350px;

        &.withNotification {
          margin-top: 8px;
        }
      }

      .notification {
        margin-top: 15px;
      }
    }

    .fetchingRoutinesErrorMessage {
      width: 350px;
    }
  }
}
