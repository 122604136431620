@import "~styles/vars";

.recordManualPassageWorklistPreview {
  .previewWarnings {
    margin-top: 15px;

    .warningsHeader {
      @include font-label-bold;
      color: $warning;
    }

    .warningsTable {
      padding-bottom: 10px;
      padding-right: 25px;
    }
  }

  .recordManualPassageWorklistTable {
    .pointerContainer {
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .pointer {
      fill: $grey-dark;
      width: 24px;
      height: 24px;
    }

    .plate {
      margin: 10px 0;
    }

    .plateName {
      @include font-text-tiny-bold;
      color: $grey-dark;
      margin-top: 5px;
      word-break: break-all;
    }
  }
}
