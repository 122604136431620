@import '~styles/vars';

.workcellHeader {
  display: flex;
  align-items: center;

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    color: $grey-dark;

    .title {
      @include font-title;
    }

    .menu {
      margin-top: 4px;
    }

    .toggleSwitch {
      margin-top: 1px;
    }
  }

  .initializeError {
    @include font-subtext-bold;
    color: $error;
  }

  .status {
    @include font-subtext;
    color: $grey-dark;

    .connecting {
      @include font-label-bold;
      color: $grey-medium;
    }

    .ready,
    .on {
      @include font-label-bold;
      color: $success;
    }

    .failedStep {
      @include font-label-bold;
      color: $grey-medium;
    }

    .busy {
      @include font-label-bold;
      color: $accent;
    }

    .faulted,
    .off {
      @include font-label-bold;
      color: $error;
    }

    .loadingIcon {
      width: 12px;
      height: 15px;
      fill: $grey-medium;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .error {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .errorIcon {
      stroke: $error;
      width: 16px;
      height: 16px;
    }

    .errorMessage {
      @include font-text-tiny;
      color: $error;
      margin-left: 4px;
    }

    .clearError {
      @include font-text-tiny;
      color: $error;
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .actionsTrigger {
    @include font-subtext;
    padding: 4px 4px 4px 14px;
    color: $grey-medium;
    border: 1px solid $grey-light;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $off-white;
    }

    .icon {
      fill: $grey-medium;
    }
  }

  .errorNotification {
    max-width: 600px;
    min-width: 400px;
  }
}
