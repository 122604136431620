@import '~styles/vars';

.culturePageHeader {
  .title {
    @include font-title-bold;
    color: $grey-dark;
  }

  .titleInner {
    padding-right: 20px;
    display: inline;
  }

  .goBackLabel {
    @include font-label;
    color: $grey-medium;

    &:hover {
      color: $grey-light;
    }
  }

  .createdAt {
    @include font-small-info;
    color: $grey-medium;
  }
}
