@import '~styles/vars';

.processStepName {
  .instrumentName {
    @include font-label;
    color: $grey-medium;
  }
  .commandName {
    @include font-subtext;
  }
}
