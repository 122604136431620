@import '~styles/vars.scss';

.authTokenDashboard {
  min-height: 100vh;
  background: white;
}

.authTokens {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    padding: 30px 80px 10px;

    .title {
      @include font-title;
    }
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .addButton {
    margin-left: 80px;
  }

  .authTokenTable {
    flex: 1 1 0;
    min-width: 0;
  }

  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    margin-bottom: 40px;
    color: $grey-medium;
  }

  .apiToken {
    @include font-monospace;
  }

  .icon {
    fill: $grey-light;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
