@import '~styles/vars';

.workflowDefinitionViewDialog {
  width: 600px;

  .title {
    @include font-section-title;
  }

  .container {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table {
    max-height: 500px;
    .stepName {
      @include font-subtext;
    }
    .stepInfo {
      @include font-small-info;
      color: $grey-medium;
      cursor: pointer;
    }
    .schedule {
      @include font-subtext;
      color: $accent;
    }
  }
}
