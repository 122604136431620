@import "~styles/vars";

.confirmUpdatesStep {
  margin-top: 30px;
  flex: 1 1 0;
  min-height: 0;
  .title {
    @include font-section-title;
    color: $grey-dark;
  }

  .contents {
    display: flex;
    margin-top: 25px;
  }

  .instrumentColumn {
    width: 100px;
    margin-right: 50px;
    height: 100%;
  }

  .dataTable {
    flex: 1 1 0;
    min-width: 0;
    height: 100%;
    margin-top: -20px;
  }
}
