@import '~styles/vars';

.button {
  @include font-subtext;
  border: none;
  outline: none;
  padding: 6px 10px;
  box-shadow: $box-shadow-lite;

  &.clickable {
    cursor: pointer;
  }

  &.normal {
    color: $grey-medium;
    border: 1px solid $grey-light;
    background-color: white;

    .icon {
      fill: $grey-medium;
    }

    &:hover {
      background-color: $off-white;
    }
  }

  &.primary {
    color: white;
    border: 1px solid $accent;
    background-color: $accent;

    .icon {
      fill: white;
    }

    &:hover {
      background-color: lighten($accent, 20%);
      border: 1px solid lighten($accent, 20%);
    }

    &.appearNormalIfNotHovered {
      color: $grey-medium;
      border: 1px solid $grey-light;
      background-color: white;

      .icon {
        fill: white;
      }

      &:hover {
        color: $white;
        background-color: $accent;
        border: 1px solid $accent;
      }
    }
  }

  &.danger {
    color: $white;
    border: 1px solid $error;
    background-color: $error;

    .icon {
      fill: $error;
    }

    &:hover {
      background-color: lighten($error, 20%);
      border: 1px solid lighten($error, 20%);
    }

    &.appearNormalIfNotHovered {
      color: $grey-medium;
      border: 1px solid $grey-light;
      background-color: white;

      .icon {
        fill: white;
      }

      &:hover {
        color: $white;
        background-color: $error;
        border: 1px solid $error;
      }
    }
  }

  &.normal,
  &.primary,
  &.danger {
    &.disabled,
    &.disabled.appearNormalIfNotHovered {
      color: $grey-light;
      background-color: $grey-lightest;
      border: 1px solid $grey-lightest;
      box-shadow: none;
      cursor: not-allowed;

      .icon {
        fill: $grey-light;
      }
    }
  }

  .icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
