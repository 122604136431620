@import "~styles/vars";

.teachPanel {
  flex: 1 1 0;
  display: flex;

  .leftSidebar {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .bodyContents {
    margin-left: 100px;
    flex: 1 1 0;
    min-width: 0;

    .safeLocationControls {
      margin-top: 20px;
    }
  }

  .freeUnfreePanel {
    width: 150px;
  }

  .gripLabel,
  .releaseLabel,
  .freeLabel,
  .unfreeLabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;

    .icon {
      fill: $accent;
    }

    .label {
      @include font-label-bold;
      color: $accent;
      margin-left: 5px;
      margin-bottom: 1px;
    }

    &:hover {
      .label {
        color: $accent-dark;
      }
      .icon {
        fill: $accent-dark;
      }
    }
    &.disabled {
      cursor: default;
      .label {
        color: $grey-light;
      }
      .icon {
        fill: $grey-light;
      }
    }
  }

  .gripReleasePanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    text-align: center;

    .gripPanel {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .input {
      width: 90px;
    }

    .releasePanel {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .currentLocations {
    margin-top: 25px;

    .location {
      .locationType {
        width: 90px;
        @include font-label;
        color: $grey-medium;
      }
      display: flex;
      align-items: center;

      > * {
        margin-top: 8px;
      }

      .locationText {
        @include font-subtext-bold;
        color: $grey-medium;
        word-spacing: 5px;
      }
    }
  }
}
