@import '~styles/vars';

.confirmCancelAllDialog {
  .text {
    @include font-subtext;
    margin-top: 10px;
  }

  .button {
    margin-top: 10px;
    align-self: flex-start;
  }

  .options {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}
