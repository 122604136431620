@import '~styles/vars';

.jogger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;

  .axisPanels {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }

  .arrow {
    border: solid $accent-light;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    height: 15px;
    width: 15px;

    &:hover {
      border: solid $accent;
      border-width: 0 3px 3px 0;
      cursor: pointer;
    }
  }
  .disabledArrow {
    border: solid $grey-light;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    height: 15px;
    width: 15px;
  }

  .zPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    .upward {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .zLabel {
      margin: 15px 0px;
      @include font-label-bold;
      color: $grey-light;
    }

    .downward {
      grid-column: 3;
      grid-row: 5;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .xyPanel {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: solid $grey-light;
    display: grid;
    grid-template-columns: repeat(5, 50px);
    grid-template-rows: repeat(5, 50px);
    place-items: center;

    .innerPanel {
      grid-column: 3;
      grid-row: 3;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: solid $grey-light;
      display: flex;
      align-items: center;
      justify-content: center;

      .innerLabel {
        @include font-label-bold;
        color: $grey-light;
        text-align: center;
        margin: 0 10px;
      }
    }

    .right {
      grid-column: 5;
      grid-row: 3;
      margin-right: 15px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .turnRight {
      grid-column: 2;
      grid-row: 2;
      place-self: baseline;
      margin-top: 10px;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
    }

    .left {
      grid-column: 1;
      grid-row: 3;
      margin-left: 15px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    .turnLeft {
      grid-column: 4;
      grid-row: 2;
      place-self: baseline;
      margin-top: 10px;
      margin-left: 35px;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }

    .forward {
      grid-column: 3;
      grid-row: 1;
      margin-top: 15px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .back {
      grid-column: 3;
      grid-row: 5;
      margin-bottom: 15px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .amountSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

    .amountLabel {
      @include font-label-bold;
      color: $grey-light;
    }

    .amountInputs {
      display: flex;
      align-items: center;

      > * {
        margin-top: 8px;
        margin-right: 12px;
      }

      .amountSelected {
        @include font-subtext-bold;
        color: $accent;
        cursor: pointer;
      }
      .amountDefault {
        @include font-subtext-bold;
        color: $grey-light;
        cursor: pointer;

        &:hover {
          color: $grey-medium;
        }
      }
    }
  }
}
