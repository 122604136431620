@import "~styles/vars";

.operatorActionOption {
  display: inline-block;
  width: 400px;

  &.default {
    .actionInner {
      padding: 16px 20px;
    }
  }

  &.condensed {
    .actionInner {
      padding: 8px 20px;
    }
  }

  .actionInner {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $off-white;
    }

    .text {
      margin-left: 16px;
      flex: 1 1 0;
      height: 81px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon {
      width: 64px;
      height: 64px;
    }

    .actionName {
      @include font-section-title;
      margin-bottom: 5px;
    }

    .actionDescription {
      color: $grey-medium;
    }
  }
}
