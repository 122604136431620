@import '~styles/vars';

.header {
  padding: 30px 30px 30px 30px;
}

.dialogBody {
  width: 750px;

  :global(.bp4-dialog-header){
    :global(.bp4-heading) {
      @include font-section-title;
    }
  }
}

.imageContainer {
  margin: auto;

  .imagePlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $off-white;
    // TODO(SWE-867): This is hardcoded right now, but should be dynamic
    width: 750px;
    height: 750px;
  }

  .image {
    width: 750px;
    background-color: $off-white;
  }
  .imageHidden {
    display: none;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 20px;
}

.leftSection {
  display: flex;
  align-items: center;
}

.prominentInfo {
  @include font-text;
  font-weight: bold;
  color: $grey-dark;
}

.subInfo {
  @include font-small-info;
  margin-top: 2px;
  color: $grey-medium;
  font-weight: 600;
}

.rightSection {
  text-align: right;
}

.imageGridContainer {
  height: 70px;
  width: 70px;
  margin-right: 20px;
  border: 1px solid $grey-light;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageGrid {

  display: flex;
  flex-direction: column;
}

.imageGridRow {
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
}

.imageGridImage {
  width: 9px;
  height: 9px;
  margin: 1.5px 1.5px 1.5px 1.5px;
  cursor: pointer;
}

.imageGridImage__selected {
  background-color: $accent;

  &:hover {
    background-color: lighten($accent, 20%);
  }
}

.imageGridImage__unselected {
  background-color: $grey-light;

  &:hover {
    background-color: $grey-lightest;
  }
}
