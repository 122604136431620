@import '~styles/vars';

.inhecoScilaLiveDoorStatus {
  @include font-label;
  color: $grey-medium;

  .doorStatusOpened {
    color: $accent;
    font-weight: bold;
  }

  .doorStatusClosed {
    color: $grey-medium;
  }
}
