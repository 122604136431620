@import '~styles/vars';

.icon {
  fill: $grey-light;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: $off-white;
  }
}

.content {
  padding: 5px 0;

  .option {
    padding: 10px 35px;
    cursor: pointer;
    display: block;
    color: $grey-dark;
    display: flex;
    align-items: center;

    .optionIcon {
      width: 20px;
      height: 20px;
      margin-right: 9px;
      fill: $grey-dark;
    }

    &:hover {
      background-color: $off-white;
    }
  }
}

.userMenuContainer {
  padding: 12px 18px;
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;

  .userImage {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }

  .userName {
    @include font-text-tiny;
    color: $grey-light;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.userProfile {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid $grey-light;
  margin-bottom: 5px;
  width: 300px;

  .userBigImageContainer {
    width: 40px;
    height: 40px;

    .userBigImage {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }

  .text {
    margin-left: 10px;
    flex: 1 1 0;
    min-width: 0;

    .orgContainer {
      display: flex;
      align-items: center;
    }

    .name {
      @include font-section-title;
      margin-bottom: 3px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .role {
      margin-left: 5px;
    }

    .org {
      @include font-subtext;
      color: $grey-dark;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
