@import "~styles/vars";

.linkCellLineLotsToCulturesAction {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 20px;
  }

  .stepper {
    margin-top: 25px;
  }
}
