@import "~styles/vars";

.lookupErrorsInlineDialog {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey-lightest;
  box-shadow: $box-shadow;
  height: calc(100% - 61px);
  margin-bottom: 30px;
  margin-top: 31px;

  .header {
    padding: 15px 25px 5px;
    display: flex;

    .headerInner {
      @include font-section-title;
      padding-top: 7px;
      color: $grey-dark;
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .label {
    @include font-label;
    color: $grey-medium;
  }

  .content {
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
    padding: 0 25px 25px;
  }

  .closeIcon {
    width: 32px;
    height: 32px;
    margin-top: 2px;
    fill: $grey-medium;
    cursor: pointer;

    &:hover {
      fill: $grey-light;
    }
  }
}
