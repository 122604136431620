@import '~styles/vars';

.placeholder {
  display: flex;
  align-items: center;

  .icon {
    height: 16px;
    width: 16px;
    fill: $grey-medium;
    margin-right: 2px;
  }

  color: $grey-medium;
  @include font-subtext;
}

.trigger {
  padding: 0;
}
