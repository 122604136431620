@import '~styles/vars';

.well {
  aspect-ratio: 1 / 1;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  .layer {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  .imagery {
    display: grid;
    grid-template-columns: repeat(var(--montage-columns), 1fr);
    grid-template-rows: repeat(var(--montage-rows), 1fr);
    gap: 6px;

    .site {
      aspect-ratio: 1 / 1;

      border-radius: 5px;
      background-image: image-set(var(--sprite-url-2x) 2x);
      background-size: calc(100% * var(--num-sites-per-montage)) calc(100% * var(--sprite-num-wells));
      background-position-y: calc(100% * (var(--sprite-well-index) / (var(--sprite-num-wells) - 1)));
      background-position-x: calc(100% * (var(--site-index) / (var(--num-sites-per-montage) - 1)));
      background-repeat: no-repeat; // Reduces confusion when debugging.
      background-color: $grey-lightest; // Empty state and loading skeleton.
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);

      .linkToImage {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &.singleImage .site {
      background-image: image-set(var(--single-image-url) 2x);
      background-size: 100% 100%;
      background-position-x: center;
    }

    &.notImaged .site {
      background-image: none;
    }
  }
}
