@import "~styles/vars";

.wizardStepper {
  display: flex;

  .steps {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    align-items: center;

    .step {
      display: flex;
      align-items: center;

      .stepTitle {
        @include font-subtext-bold;
      }

      .stepIndex {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-subtext;
        border-radius: 6px;
        margin-right: 9px;
        .checkmarkIcon {
          width: 16px;
          height: 16px;
          fill: white;
        }
      }

      &.upNext {
        .stepTitle {
          color: $grey-light;
        }
        .stepIndex {
          border: 1px $grey-light solid;
          color: $grey-light;
        }
      }
      &.active {
        .stepTitle {
          color: $accent;
        }
        .stepIndex {
          background-color: $accent;
          color: $white;
        }
      }
      &.complete {
        cursor: pointer;

        .stepTitle {
          color: $grey-dark;
        }
        .stepIndex {
          background-color: $grey-dark;
          color: $white;
        }
        &:hover {
          .stepTitle {
            color: $grey-medium;
          }
          .stepIndex {
            background-color: $grey-medium;
            color: $white;
          }
        }
      }
    }

    .connector {
      margin: 0 8px 0 6px;
      width: 32px;
      @include horizontal-border($grey-light, 4px, 1px);
    }
  }

  .nextButton {
    padding: 8px 40px;
  }
}
