@import "~styles/vars";

.recordManualPassageAction {
  height: 100%;
  display: flex;
  flex-direction: column;

  .actionHeader {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .fill {
      flex: 1 1 0;
      min-width: 0;
    }
    .button {
      padding: 8px 40px;
    }
  }
}

.loadingMessageBox {
  width: 400px;
  padding: 30px 0;
  margin-top: 30px;
}
