@import '~styles/vars';

.futureRoutinesConsumablePlannerDialog {
  width: 600px;

  .dateInput {
    margin-top: 15px;
    width: 300px;
  }
  .bigErrorMessage {
    @include font-section-title;
    color: $error;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .bigSuccessMessage {
    @include font-section-title;
    color: $success;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .checkmarkIcon {
    width: 20px;
    height: 20px;
    fill: $success;
    margin-right: 5px;
  }
  .warningIcon {
    width: 20px;
    height: 20px;
    fill: $error;
    margin-right: 3px;
    margin-left: -7px;
  }
  .numFutureRoutinesMessage {
    @include font-info;
    color: $grey-medium;
    margin-top: 2px;
  }

  .loadingMessage {
    margin-top: 10px;
  }

  .error {
    margin-top: 10px;
  }

  .queuedRoutinesWarning {
    margin-top: 20px;
  }
}
