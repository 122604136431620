@import '~styles/vars';

.loadUnloadItemsAction {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 20px;
  }
}
