@import '~styles/vars';

.datasetViewerDialog {
  width: 800px;

  .title {
    @include font-section-title;
    margin-bottom: 10px;
  }

  .randomize {
    cursor: pointer;
    display: flex;
    margin: 20px 0;

    .checkbox {
      margin-right: 5px;
    }
  }

  .controls {
    margin-top: 20px;

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .data {
    margin-top: 10px;
    max-height: 400px;
    overflow: auto;
    padding: 10px;
    border: 1px solid $grey-light;
  }

  .uploadsTable {
    max-height: 400px;
  }

  .initiateUploadButton {
    @include font-label-bold;
    color: $accent;
    cursor: pointer;

    &:hover {
      color: lighten($accent, 20%);
    }
  }

  .retryStructureRawData {
    @include font-label-bold;
    margin-top: 10px;
    text-align: right;
    color: $grey-medium;
    cursor: pointer;

    &:hover {
      color: $grey-dark;
    }
  }

  .desktopNotification {
    margin-top: 10px;
  }

  .link {
    color: $accent;
  }
}
