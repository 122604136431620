@import "~styles/vars";

.text {
  &.smallText {
    @include font-text-tiny;
  }

  &.hover {
    cursor: help;
  }

  &.click {
    cursor: pointer;
  }

  &.default {
    &.smallText {
      color: $grey-medium;
    }

    &.hover {
      text-decoration: underline dashed $grey-medium;
    }

    &.click {
      text-decoration: underline;
      text-decoration-color: $grey-medium;
    }
  }

  &.warning {
    color: $warning;
    &.hover {
      text-decoration: underline dashed $warning;
    }

    &.click {
      text-decoration: underline;
      text-decoration-color: $warning;
    }
  }

  &.error {
    color: $error;
    &.hover {
      text-decoration: underline dashed $error;
    }

    &.click {
      text-decoration: underline;
      text-decoration-color: $error;
    }
  }
}

.defaultPopover {
  @include font-subtext;
  max-width: 350px;
  padding: 20px;
  color: $grey-dark;
}
