@import '~styles/vars';

.workcellConfigDialog {
  width: 820px;

  .title {
    @include font-section-title;
    margin-bottom: 10px;
  }
}
