@import '~styles/vars';

.terminateWorkflowDialog {
  width: 500px;
  padding: 40px;

  .contents {
    @include font-subtext;
  }
}
