@import '~styles/vars';

.createWorkflowDialog {
  width: 500px;

  .contents {
    width: 350px;
  }

  .workflowDefinitionSelect {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .trigger {
    width: 350px;
  }
}

.popover {
  width: 350px;
}
