@import '~styles/vars';

.datasetDownloadDialog {
  width: 760px;

  .header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .text {
      .title {
        @include font-section-title;
        margin-bottom: 5px;
      }
      .subtitle {
        @include font-subtext;
        color: $grey-medium;
      }
    }
  }

  .tableContainer {
    height: 400px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .searchInput {
    margin-right: $controls-margin;
  }

  .button {
    width: 200px;
  }

  .link {
    width: 200px;
  }

  .editorContainer {
    margin-top: 20px;
    height: 400px;
    overflow: auto;
    padding: 10px;
    border: 1px solid $grey-light;
    margin-bottom: 20px;
  }

  .editor {
    @include font-monospace;
  }

  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    color: $grey-medium;
  }
}
