@import '~styles/vars';

.main {
  margin: 0 40px 40px;
  display: grid;
  grid-template-columns: 1fr 330px;
  grid-template-areas:
    'header header'
    'subheader unused'
    'hero sidebar';
  column-gap: 24px;
  row-gap: 9px;
}

.title {
  @include font-title;
  grid-area: header;
  height: 50px;
  margin: 20px 0 17px;
  color: $grey-dark;
}

.heroToolbar {
  grid-area: subheader;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .heroLabel {
    @include font-small-info;
    height: 18px;
    color: $grey-dark;
  }
}

.status {
  @include font-label-bold;
  margin-left: 20px;

  &.checkedIn {
    color: $success;
  }
}

// TODO(SWE-1309): Disable all transitions if demoing. (Add a flag somewhere?)
