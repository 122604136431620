@import '~styles/vars';

.link {
  margin-left: 10px;
}

.controls {
  margin-top: 5px;
}

.error {
  @include font-text-tiny;
  color: $grey-dark;
}
