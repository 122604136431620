@import '~styles/vars.scss';

.container {
  margin: 3em auto;
  padding: 0 3em;
  text-align: center;
}

.title {
  @include font-title;
}

.message {
  @include font-text;
  margin: 1em auto 0;
  max-width: 500px;
  text-wrap: balance;

  a {
    color: $accent;

    &:hover {
      color: $accent-light;
    }
  }
}
