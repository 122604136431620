@import '~styles/vars';

.d3Legend {
  //Placeholder
  padding: 0;

  .axis {
    text {
      @include font-label;
      color: $grey-medium;
    }

    line {
      display: none;
    }
  }
}
