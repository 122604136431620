@import "~styles/vars";

.page {
  margin: 2em;

  p {
    margin: 12px 0;
  }

  h3 {
    margin: 1em 0;
  }

  hr {
    margin: 30px 0;
  }

  fieldset {
    margin: 0.5em 0;

    label {
      @include font-label-bold;
    }
  }

  legend {
    @include font-subtext-semibold;
  }

  th, td {
    border: 1px solid $grey-lightest;
    padding: 4px 6px;

    .blank {
      color: $grey-light;
    }
  }

  th {
    @include font-subtext-semibold;
  }

  ol {
    margin: revert;
    padding: revert;
  }

  details {
    margin: 8px 0;

    summary {
      cursor: pointer;
    }
  }
}

.layout {
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
}

.sidebar {
  flex: 0 0 200px;

  p {
    @include font-small-info;
  }
}

.sticky {
  position: sticky;
  top: 1em;
}

.instructions {
  max-width: 700px;
}

.productOption {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.presetButton {
  display: block;
  width: 100%;
  margin: 8px 0;
}

.content {
  margin-top: 1em;
  flex: 1 1 auto;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.error {
  color: $error;
}

.imageSetDetails {
  margin: 3em 0 1em;
}

.imageGallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  gap: 8px;

  img {
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
  }
}

.spriteGallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, 210px);
  gap: 12px;

  .identifier {
    @include font-subtext;
    word-wrap: break-word;
    cursor: pointer;

    &::after {
      content: " Copy ID";
      @include font-label-small-bold;
      opacity: 0;
    }

    &:hover {
      opacity: 0.6;

      &::after {
        opacity: 1;
      }
    }
  }

  .extra {
    @include font-text-very-tiny;
    color: $grey-dark;
  }

  a:hover {
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: left top;
  }
}
