@import '~styles/vars';

.readOnlyField {
  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    height: 15px;

    .iconContainer {
      margin-left: 2px;
    }

    .icon {
      margin-top: 4px;
      width: 12px;
      height: 12px;
      cursor: help;
      fill: $grey-medium;
    }
  }

  .display {
    @include font-subtext;
  }

  &.condensed {
    .label {
      margin-bottom: 2px;
    }

    .display {
      @include font-text-tiny;
    }
  }
}

.helpContent {
  @include font-subtext;
  max-width: 350px;
  padding: 20px;
  color: $grey-dark;

  .fieldName {
    @include font-text;
    margin-bottom: 10px;
  }

  .type,
  .description {
    margin-bottom: 5px;
  }

  .default {
    @include font-label;
    color: $grey-light;
    word-break: break-word;
  }
}
