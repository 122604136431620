@import "~styles/vars";

.bulkLoadCulturePlatesInstrumentSelect {
  .controls {
    display: flex;
  }

  .instrumentSelectTrigger {
    width: 280px;
  }
  .shelfSelect {
    margin-left: 20px;
  }
  .shelfSelectTrigger {
    width: 50px;
  }
  .noValidShelves {
    margin-top: 8px;
    width: 300px;
  }
}

.instrumentSelectPopover {
  width: 280px;
}
.shelfSelectPopover {
  width: 50px;
}
