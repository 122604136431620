@import '~styles/vars';

.processStepTooltip {
  background-color: white;
  box-shadow: $box-shadow;
  border: 1px solid $grey-light;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  min-width: 350px;

  .commandName {
    @include font-text-tiny-bold;
    margin-top: 2px;
  }

  .scheduledDate {
    color: $grey-dark;
    font-style: italic;
  }

  .subheader {
    @include font-label;
    color: $grey-medium;
  }

  .fill {
    min-width: 0;
    flex: 1 1 0;
  }

  .right {
    margin-left: 40px;
    text-align: right;
  }

  .status {
    display: inline-flex;
  }

  .runtime {
    @include font-text-tiny-bold;
    margin-top: 2px;
  }
}
