@import '~styles/vars';

.popoverTrigger {
  cursor: pointer;
  text-decoration: underline;

  &.success {
    @include font-label-bold;
    color: $success;
  }

  &.warning {
    @include font-label-bold;
    color: $warning;
  }

  &.error {
    @include font-label-bold;
    color: $error;
  }

  &.accent {
    @include font-label-bold;
    color: $accent;
  }

  &.inactive {
    @include font-label-bold;
    color: $grey-medium;
  }
}

.controls {
  border-top: 1px solid $grey-light;
}

.recoveryButton {
  display: block;
  margin: 0 auto;
  padding: 5px 0;
}

.loadingIcon {
  width: 12px;
  height: 15px;
  fill: $grey-medium;
}

.table {
  .status {
    display: inline-flex;
  }

  .statusContainer,
  .name {
    height: 30px;
    display: flex;
    align-items: center;
  }

  .statusContainer {
    justify-content: flex-end;
  }
}
