@import "~styles/vars";

.header {
  margin-top: 30px;
  height: 65px;

  display: flex;
  align-items: center;

  .titleContainer {
    display: flex;
    align-items: center;
    color: $grey-dark;

    .title {
      @include font-title;
    }

    .menu {
      margin-top: 7px;
    }
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .culturesNeedAttention {
    max-width: 600px;
    min-width: 400px;
  }

  .culturesNeedAttentionInner {
    min-height: 75px;
    display: flex;
    align-items: center;
  }

  .culturesNeedAttentionTitle {
    @include font-subtext-bold;
    margin-bottom: 2px;
  }

  .culturesNeedAttentionLink {
    @include font-subtext;
    color: #666666;
    text-decoration: underline;
  }
}
