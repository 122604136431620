@import '~styles/vars';

.archivedItems {
  display: flex;
  flex-direction: column;
  height: 100%;

  .table {
    flex: 1 1 0;
    min-height: 0;
  }
}
