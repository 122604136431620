@import '~styles/vars';

.operatorActionSelect {
  .trigger {
    width: 350px;
  }
}

.popover {
  width: 350px;

  .action {
    display: flex;
    align-items: center;
    color: $grey-dark;

    .iconContainer {
      width: 45px;
      padding: 5px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
