@import '~styles/vars';

.hitpickingMicroplate {
  //Placeholder
  padding: 0;
}

.plateLabel {
  @include font-label-bold;
  color: $grey-medium;
  margin-top: 5px;
}
