@import '~styles/vars.scss';

.dialog {
  padding: 30px;
  background-color: $white;
  border-radius: 0;

  &.noPadding {
    padding: 0;
  }

  .title {
    @include font-section-title;
  }

  .subtitle {
    @include font-label;
    color: $grey-medium;
  }

  .info {
    @include font-text-tiny;
    color: $grey-medium;
    font-style: italic;
    margin: 10px 0;
  }

  .footer {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    &.left {
      margin-top: 25px;
      justify-content: flex-start;
    }
  }

  .footerError {
    margin-top: 15px;
  }

  .button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
