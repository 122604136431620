@import '~styles/vars';

:global(.bp4-datepicker) :global(.DayPicker-Day):global(.DayPicker-Day--selected) {
  background-color: $accent;

  &:hover {
    background-color: $accent-dark;
  }
}

:global(.bp4-datepicker-footer) {
  :global(.bp4-button) {
    @include font-label-bold;
    color: $grey-medium;
    &:hover {
      color: $grey-light;
    }

    &:first-child {
      color: $accent-dark;

      &:hover {
        color: $accent;
      }
    }
  }
}
