@import '~styles/vars';

.trigger {
  padding: 0;
}

.option {
  height: 30px;
  display: flex;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    fill: $accent;
    margin-right: 6px;
  }

  @include font-subtext-bold;
  color: $accent;
}

.triggerIcon {
  fill: $accent !important;
  margin-right: 0;
}

.loadUnloadItemsTypeSelect.disabled {
  .option {
    .icon {
      fill: $grey-medium !important;
    }

    color: $grey-medium;
  }

  .triggerIcon {
    fill: $grey-medium !important;
  }

  .trigger::after {
    background: none;
  }
}
