@import "~styles/vars";

.toggleSwitch {
  display: flex;
  align-items: center;
  cursor: pointer;

  .switchOuter {
    width: 54px;
    height: 20px;
    border-radius: 10px;
    transition: background-color 400ms, border-color 400ms;
    margin-right: 9px;

    .switchInner {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      transition: transform 400ms cubic-bezier(0.75, 0, 0.25, 1),
        background-color 400ms;
    }
  }

  &.loading {
    cursor: default;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .onLabel,
  .offLabel,
  .loadingLabel {
    @include font-subtext-bold;
  }

  .loadingContainer {
    display: flex;
  }

  .onLabel {
    color: $accent;
  }
  .offLabel {
    color: $grey-dark;
  }
  .loadingLabel {
    color: $grey-medium;
    margin-right: 3px;
  }

  &.disabled {
    .onLabel,
    .offLabel {
      color: $grey-medium;
    }
  }

  .loadingIcon {
    width: 16px;
    height: 16px;
    margin-left: 3px;
    fill: $grey-medium;
  }

  &.on {
    .switchOuter {
      border: 1px solid $accent;
      background-color: $accent;

      &:hover {
        background-color: lighten($accent, 20%);
        border: 1px solid lighten($accent, 20%);
      }

      .switchInner {
        transform: translate(36px, 3px);
        background-color: $white;
      }
    }
  }

  &.off {
    .switchOuter {
      border: 1px solid $grey-dark;
      background-color: $white;

      &:hover {
        background-color: $grey-lightest;
      }

      .switchInner {
        transform: translate(5px, 3px);
        background-color: $grey-medium;
      }
    }
  }

  &.loading,
  &.disabled {
    .switchOuter {
      border: 1px solid $grey-medium;
      background-color: $grey-lightest;

      &:hover {
        background-color: $grey-lightest;
        border: 1px solid $grey-medium;
      }

      .switchInner {
        background-color: $grey-light;
      }
    }
  }
}
