@import '~styles/vars';

.inputWithUnits {
  .wrapper {
    display: flex;

    .innerInput {
      width: 100%;
    }

    .unit {
      @include font-text-tiny;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $off-white;
      color: $grey-medium;
      width: 80px;
      border: 1px #ccc solid;
      border-left: none;
    }
  }

  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 5px;
  }

  .errorMessage {
    margin-top: 4px;
  }
}
