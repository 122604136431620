@import '~styles/vars';

@mixin slot-size($wfactor, $hfactor) {
  .slot {
    width: 50px * $wfactor;
    height: 13px * $hfactor;

    .slotInner {
      .bar {
        width: 4px * $wfactor;
      }

      .name {
        padding: 0 4px * $wfactor;
      }
    }
  }
}

@mixin color($background, $color) {
  background-color: $background;
  color: $color;

  .barInner {
    background-color: $color;
    min-height: 1px;
  }
}

@mixin color-stripes($background-one, $background-two, $color) {
  background: repeating-linear-gradient(
    45deg,
    $background-one 0px,
    $background-one 10px,
    $background-two 10px,
    $background-two 20px
  );
  color: $color;

  .barInner {
    background-color: $color;
    min-height: 1px;
  }
}

.processItemVizSlot {
  display: block !important;
  user-select: none;
  width: max-content;

  &:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.normal {
    @include slot-size(1, 1);
  }

  &.ot2DeckSlot {
    @include slot-size(1, 2.5);
  }

  &.tecanDeckSlot {
    @include slot-size(1, 1.5);
  }

  &.large {
    @include slot-size(1.25, 1.25);

    .slot .slotInner .name {
      @include font-text-tiny-bold;
    }
  }

  &.mini {
    @include slot-size(0.7, 0.7);

    .slot .slotInner .name {
      display: none;
    }
  }

  &.micro {
    @include slot-size(0.45, 0.45);

    .slot .slotInner .name {
      display: none;
    }
  }

  .slot {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &.displayIconOnHover:hover {
      .slotInner {
        display: none;
      }

      .slotInnerAddIcon,
      .slotInnerRemoveIcon {
        display: block;
      }
    }

    &.disabled {
      @include color($background: $grey-lightest, $color: $grey-medium);
    }

    &.invalid {
      @include color($background: $grey-lightest, $color: $grey-medium);
    }

    &.unselectable {
      @include color-stripes(
        $background-one: $grey-light,
        $background-two: $grey-lightest,
        $color: $grey-medium
      );
    }

    &.emptyConsumable,
    &.stringOnlyProcessItem {
      .barInner {
        min-height: 0 !important;
      }

      &.defaultColor {
        @include color($background: $error-dark-bg, $color: $error);
      }

      &.highlight {
        @include color($background: $warning-dark-bg, $color: $warning);
      }
    }

    &.consumable,
    &.processItem {
      &.defaultColor {
        @include color($background: $accent-bg-viz, $color: $accent-dark);
      }

      &.highlight {
        @include color($background: $warning-dark-bg, $color: $warning);
      }
    }

    &.culturePlate {
      &.defaultColor {
        @include color($background: $accent, $color: $white);
      }

      &.highlight {
        @include color($background: $warning, $color: $white);
      }
    }
    &.emptySlot {
      &.defaultColor {
        @include color($background: $accent-bg, $color: $accent-dark);
      }

      &.highlight {
        @include color($background: $warning-dark-bg, $color: $warning);
      }
    }

    .slotInner {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      .bar {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
      }

      .name {
        @include font-text-very-tiny;
        @include one-line;
        text-align: center;
        flex: 1 1 0;
        min-width: 0;
      }
    }

    .slotInnerAddIcon {
      display: none;
      @include font-text-tiny;
    }

    .slotInnerRemoveIcon {
      display: none;
      @include font-text-tiny;
    }

    &.hidden {
      opacity: 0;
    }

    &.clickable {
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }
  }
}
