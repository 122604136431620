@import '~styles/vars';

.page {
    padding: 0 40px 80px;
}

.pageTitle {
    @include font-title;
    margin: 40px 0;
}

.plateChooser {
    margin: 40px 0;
    display: flex;
    gap: 25px;

    .item {
        opacity: 0.7;
        filter: grayscale(1);
        cursor: pointer;

        .label {
            @include font-label-bold;
            margin-bottom: 0.5em;
            color: $grey-dark;
        }

        &:hover {
            opacity: 1;
            filter: grayscale(0);
        }

        &.active {
            opacity: 1;
            filter: grayscale(0);
            cursor: default;
        }
    }
}

.controls {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .trigger {
        width: 150;
        margin-right: 20px;
    }
}

.sliderGroup {
    .label {
        @include font-label;
        color: $grey-medium;
    }

    .input {
        width: 534px;
        padding: 8px 0;
    }
}

.popover {
    width: 266px;
}

.viewer {
    display: flex;
    align-items: flex-start;
    gap: 2em;
}

.plate {
    flex: 60% 1 1;
    margin-left: calc(-1.5em - 2px);
    display: grid;
    grid-template-rows: 1.5em repeat(8, 1fr);
    grid-template-columns: 1.5em repeat(12, 1fr);
    grid-gap: 3px;
}

.columnHeader {
    text-align: center;
    color: $grey-medium;

    &.active {
        font-weight: bold;
        color: $grey-darkest;
    }
}

.rowHeader {
    margin-right: 0.5em;
    text-align: right;
    color: $grey-medium;
    grid-column-start: 1;
    grid-column-end: 2;
    align-self: center;

    &.active {
        font-weight: bold;
        color: $grey-darkest;
    }
}

.well {
    background: lightgray;

    img,
    video {
        width: 100%;
    }
}

.detail {
    flex: 30% 1 1;

    .header {
        margin: -20px 0 0.5em;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .wellCoordinates {
            font-size: 2em;
        }

        .day {
            @include font-info;
            color: $grey-dark;
        }
    }

    img,
    video {
        width: 100%;
    }
}
