@import '~styles/vars';

.toaster {
  // Override position: absolute in blueprint.js
  position: fixed !important;

  :global(.bp4-toast):global(.bp4-intent-primary) {
    background-color: $accent !important;

    :global(.bp4-button) {
      background-color: $accent !important;
    }
  }

  :global(.bp4-toast):global(.bp4-intent-warning) {
    background-color: $warning !important;
    color: white;

    :global(.bp4-button) {
      background-color: $warning !important;

      &:last-child > :global(.bp4-icon-cross) {
        color: white !important;
      }
    }
  }
}
