@import '~styles/vars';

.cultureImageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .thumbnailContainer {
    cursor: pointer;
    margin-bottom: 20px;
  }

  .thumbnail {
    width: 240px;
    background-color: $off-white;
  }

  .thumbnailCaption {
    @include font-text-tiny;
    margin-top: 2px;
    color: $grey-medium;

    .emphasis {
      font-weight: bold;
    }
  }
}
