@import '~styles/vars';

.button {
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: $grey-medium;

  &.clickable {
    cursor: pointer;
  }

  .icon {
    fill: $grey-medium;
  }

  &:hover {
    color: $grey-dark;

    .icon {
      fill: $grey-dark;
    }
  }

  &.disabled {
    color: $grey-light;
    cursor: not-allowed;

    .icon {
      fill: $grey-light;
    }
  }

  .icon {
    margin-right: 3px;
    width: 14px;
    height: 14px;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.minimalTiny {
    @include font-text-tiny;
  }
  &.minimal {
    @include font-subtext;
  }

  &.minimalBold {
    @include font-label-bold;
  }

  &.primary:not(.disabled) {
    color: $accent;

    .icon {
      fill: $accent;
    }

    &:hover {
      color: lighten($accent, 20%);

      .icon {
        fill: lighten($accent, 20%);
      }
    }

    &.appearNormalIfNotHovered {
      color: $grey-medium;

      .icon {
        fill: $grey-medium;
      }

      &:hover {
        color: $accent;

        .icon {
          fill: $accent;
        }
      }
    }
  }

  &.danger:not(.disabled) {
    color: $error;

    &.appearNormalIfNotHovered {
      color: $grey-medium;
    }

    .icon {
      fill: $error;
    }

    &:hover {
      color: lighten($error, 20%);

      .icon {
        fill: lighten($error, 20%);
      }
    }

    &.appearNormalIfNotHovered {
      color: $grey-medium;

      .icon {
        fill: $grey-medium;
      }

      &:hover {
        color: $error;

        .icon {
          fill: $error;
        }
      }
    }
  }
}
