@import "~styles/vars";

.uploadMetadataStep {
  display: flex;
  margin-top: 30px;
  flex: 1 1 0;
  min-height: 0;
  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-bottom: 10px;
  }
  .inputColumn {
    width: 550px;
    margin-right: 50px;

    .textarea {
      width: 100%;
    }
  }
  .previewColumn {
    flex: 1 1 0;
    min-width: 0;
  }

  .viewAcceptedDataSchemas {
    margin-top: 10px;
  }
}
