@import '~styles/vars';

$executionCommandSelectWidth: 250px;

.genericInstrumentControlBox {
  width: 250px;

  .executionCommandControls {
    .trigger {
      width: $executionCommandSelectWidth;
    }

    .button {
      margin-top: 20px;
      width: $executionCommandSelectWidth;
    }

    .inputContainer {
      margin-top: 20px;
    }

    .input {
      width: $executionCommandSelectWidth;
    }
  }

  .busyMessageContainer {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .busyIcon {
      stroke: $grey-medium;
      width: 16px;
      height: 16px;
    }

    .busyMessage {
      @include font-text-tiny;
      color: $grey-medium;
      margin-left: 4px;
    }
  }

  .errorMessageContainer {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .errorIcon {
      stroke: $error;
      width: 16px;
      height: 16px;
    }

    .errorMessage {
      @include font-text-tiny;
      color: $error;
      margin-left: 4px;
    }
  }

  .recoveryControls {
    margin-top: 30px;

    .recoveryMessage {
      @include font-text-tiny;
      color: $grey-dark;
      margin-bottom: 20px;
    }
  }

  .recoveryCommandMessage {
    margin-top: 30px;
    @include font-text-tiny;
    color: $grey-dark;
    font-style: italic;
  }
}

.executionCommandSelectPopover {
  width: $executionCommandSelectWidth;
}
