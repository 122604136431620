@import '~styles/vars';

.instrumentStatusText {
  @include font-label;
  color: $grey-medium;

  .ready {
    @include font-label-bold;
    color: $success;
  }

  .busy {
    @include font-label-bold;
    color: $accent;
  }

  .error {
    @include font-label-bold;
    color: $error;
  }
}
