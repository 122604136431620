// Browser CSS reset.
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
pre,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

input,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  &:focus {
    outline: none;
  }
}

button {
  font-family: inherit;
}

textarea {
  padding: 10px;
  // textarea is inline by default, which causes an extra gap underneath (for descenders)
  display: block;
}

// Removes small gap under images.
img {
  display: block;
}

:global(.bp4-menu) {
  min-width: 0 !important;
}
