@import "~styles/vars";

.processItems {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.processItemsCheckbox {
  margin-bottom: 8px;
  color: $grey-dark;
}

.processItemsSearchInput {
  margin-bottom: 16px;
}
