@import '~styles/vars';

.transferStations {
  display: flex;
  flex-direction: column;
  height: 100%;

  .liveNotification {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .processItem {
    margin: 10px 0;
  }
}
