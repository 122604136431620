@import '~styles/vars';

.queuedRoutines {
  display: flex;
  flex-direction: column;
  height: 100%;

  .dateHeader {
    margin-top: 30px;
  }

  .routineList {
    flex: 1 1 0;
    min-height: 0;
  }

  .header {
    display: flex;

    .title {
      @include font-subtitle;
      color: $grey-darkest;
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .controls {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    .option {
      margin-left: 15px;
      color: $grey-medium;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
