@import '~styles/vars';

.serviceConfigDialog {
  width: 600px;

  .title {
    @include font-section-title;
    margin-bottom: 10px;
  }

  .configTab {
    // The height styling needs some more work.
    max-height: 400px;
  }
}
