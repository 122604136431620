@import '~styles/vars';

.workflowIcon {
  height: 12px;
  width: 12px;
  fill: $grey-darkest;
}

.popover {
  @include font-subtext;
  max-width: 350px;
  padding: 20px;
  color: $grey-dark;
}
