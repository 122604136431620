@import '~styles/vars';

.reloadItemsAction {
  height: 100%;
  display: flex;
  flex-direction: column;

  .description {
    @include font-small-info;
    color: $grey-medium;
    width: 300px;
  }

  .contents {
    display: flex;
    height: 100%;
  }

  .left {
    display: flex;
    flex-direction: column;
  }

  .appHeader {
    margin-top: 20px;
  }

  .instrumentSelect {
    margin-top: 30px;
  }

  .inputContainer {
    margin-top: 30px;
  }

  .input {
    width: 300px;
  }

  .warningContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 8px;

    .icon {
      stroke: $grey-medium;
      width: 16px;
      height: 16px;
    }

    .message {
      @include font-text-tiny;
      color: $grey-medium;
      margin-left: 4px;
    }
  }

  .controls {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    height: 100%;
  }

  .button {
    width: 300px;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .right {
    margin-left: 100px;
  }

  .storageViz {
    margin-top: 30px;
  }

  .loadingMessageBox {
    margin-top: 20px;
    width: 300px;
  }

  .notification {
    width: 300px;
    margin-top: 20px;
  }

  .infoNotification {
    width: 300px;
  }

  .select {
    margin-top: 30px;
  }

  .trigger {
    width: 300px;
  }

  .changes {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .changesSummary {
      font-style: italic;
      color: $grey-medium;
      margin-bottom: 10px;
    }

    .changeList {
      flex: 1 1 0;
      min-height: 0;
      overflow: auto;
    }
  }

  .change {
    padding: 5px 0;
  }
}

.popover {
  width: 300px;
}
