@import '~styles/vars';

.instrumentDrivers {
  display: flex;
  flex-direction: column;
  height: 100%;

  .type {
    @include font-label;
    color: $grey-dark;
  }

  .table {
    flex: 1 1 0;
    min-height: 0;
  }

  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-bottom: 40px;
    color: $grey-medium;
  }

  .controls {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .icon {
    fill: $grey-medium;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      fill: $grey-dark;
    }
  }
}
