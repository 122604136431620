@import '~styles/vars';

.cellLineFields {
  display: flex;
}

.cellLineField {
  margin-right: 98px;
}

.fieldLabel {
  @include font-label;
  color: $grey-medium;
  margin-bottom: 3px;
}

.cellLinePlates {
  margin-top: 45px;
}

.cellLinePlatesHeader {
  @include font-text-bold;
}

.rowPlateLabel {
  @include font-text;
  color: $grey-dark;
}

.chartLabel {
  @include font-label-small-bold;
  color: $grey-medium;
  margin-top: 5px;
  text-align: center;
  width: 270px;
}
