@import '~styles/vars';

.processItemVizEditable {
  padding-bottom: 10px;
  width: max-content;
}

.emptyButton {
  margin-top: 2px;
}

.popover {
  @include font-label;
  color: $grey-medium;
  padding: 10px;
}
