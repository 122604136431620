@import '~styles/vars.scss';

.team {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    padding: 30px 80px 10px;

    .title {
      @include font-title;
    }

    .subtext {
      @include font-subtext;
      color: $grey-medium;
    }
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .addButton {
    margin-left: 80px;
  }

  .userTable {
    flex: 1 1 0;
    min-width: 0;
  }

  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    margin-bottom: 40px;
    color: $grey-medium;
  }

  .icon {
    fill: $grey-medium;
  }

  .lastLogin {
    @include font-subtext;
    color: $grey-medium;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.userProfile {
  display: flex;
  align-items: center;
  padding-right: 15px;
  margin-bottom: 5px;
  width: 300px;

  .userBigImageContainer {
    width: 40px;
    height: 40px;

    .userBigImage {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }

  .text {
    margin-left: 10px;
    flex: 1 1 0;
    min-width: 0;

    .orgContainer {
      display: flex;
      align-items: center;
    }

    .name {
      @include font-section-title;
      margin-bottom: 3px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .role {
      margin-left: 5px;
    }

    .org {
      @include font-subtext;
      color: $grey-dark;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.pillContainer {
  height: 100%;

  display: flex;
  align-items: center;
}

.defaultUserBigImage {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grey-lightest;

  .userIcon {
    width: 24px;
    height: 24px;
    fill: $grey-light;
  }
}
