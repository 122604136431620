@import '~styles/vars';

.issue {
  @include font-label-bold;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  &.flushLeft {
    margin-left: -9px;
  }
}

.issueIcon {
  height: 20px;
}

.issueIcon__warning {
  fill: #de911d;
}

.issueIcon__error {
  fill: #d64545;
}

.warning {
  color: #de911d;
}

.error {
  color: #d64545;
}
