@import '~styles/vars';

.workcell {
  width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .tabPanel {
    height: 100%;
  }

  .schedule {
    height: 100%;
  }
}
