@import "~styles/vars";

.groupedErrorTable {
  .errorGroupHeader {
    @include font-subtext-bold;
    padding: 10px 0;
    position: sticky;
    top: 0px;
  }

  .errors {
    padding: 5px 0;
  }

  .error {
    @include font-subtext;
    margin-bottom: 10px;
    margin-left: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
