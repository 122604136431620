@import '~styles/vars';

.editInstrumentDriverDialog {
  width: 400px;
  padding: 40px 50px 50px;

  .container {
    margin-top: 25px;
    display: flex;
  }

  .footer {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  .trigger,
  .input,
  .button {
    width: 300px;
  }

  .inputContainer {
    margin: 5px 0;
  }

  .title {
    @include font-section-title;
  }

  .subtitle {
    @include font-subtext-bold;
    color: $grey-dark;
  }

  .expandOptions {
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .bigNotification {
    margin-top: 10px;
  }

  .inputFields {
    width: 300px;
  }

  .checkboxOptionsContainer {
    margin-top: 30px;

    .checkboxOption {
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .instrumentName,
  .display {
    margin-bottom: 20px;
  }

  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 5px;
  }
}

.inputs,
.displayText {
  width: 300px;
}

.popover {
  width: 300px;
}
