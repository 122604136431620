@import "~styles/vars";

.instrumentHeader {
  display: flex;
  align-items: center;

  .instrumentImage {
    width: 50px;
    height: 50px;
  }

  .instrumentText {
    margin-left: 10px;
  }

  .fill {
    flex: 1 1 0;
  }

  &.big {
    .instrumentStatusText {
      @include font-label;
      margin-top: 2px;
    }
    .instrumentCategory {
      margin-bottom: -4px;
    }
  }
}
