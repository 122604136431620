@import '~styles/vars';

.jsonEditDialogTab {
  &.flex {
    display: flex;
    flex-direction: column;

    .textarea {
      flex: 1 1 0;
      min-height: 0;
    }
  }

  &.default {
    .textarea {
      height: 300px;
    }
  }

  .textarea {
    margin-top: 20px;
    width: 100%;
  }

  .controls {
    display: flex;
    margin-top: 20px;

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .error {
    color: $error;
    margin-top: 10px;
  }
}
