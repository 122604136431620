@import "~styles/vars";

.safeLocationControls {
  .controlLabel {
    color: $grey-dark;
    @include font-subtext-bold;
    margin-bottom: 5px;
  }

  .select {
    width: 300px;
  }

  .trigger {
    width: 300px;
  }

  .safeLocationLabel {
    margin-top: 25px;
    margin-bottom: 5px;
    @include font-label;
    color: $grey-medium;
  }

  .selectedLocation {
    margin-top: 10px;
    .noSafeLocation {
      @include font-info;
      color: $grey-medium;
    }
  }

  .safeLocationButtons {
    margin-top: 15px;
    display: flex;
    column-gap: 15px;
  }

  .safeLocationText {
    @include font-subtext;
    color: $grey-dark;
    word-spacing: 5px;
  }
  .safeLocationTextWithHighlight {
    @include font-subtext;
    color: $accent;
    word-spacing: 5px;
  }

  .seqFilePath {
    @include font-small-info;
    color: $grey-medium;
    text-decoration: underline dashed $grey-medium;
    cursor: pointer;
  }
}
.popover {
  width: 300px;
}

.seqFilePathPopover {
  @include font-text-tiny;
  max-width: 400px;
  padding: 20px;
  word-wrap: break-word;
  color: $grey-dark;
}
