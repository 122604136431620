@import '~styles/vars';

.date {
  @include font-subtitle;
  display: inline-flex;
  align-items: center;
  color: $grey-dark;
  cursor: pointer;

  &:hover {
    color: $grey-medium;

    .icon {
      fill: $grey-light;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    stroke: none;
    fill: $grey-medium;
    margin-top: 1px;
  }
}
