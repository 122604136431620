@import '~styles/vars';

.noWorkflowText {
  padding: 15px 0;
  .noWorkflow {
    @include font-small-info;
    color: $grey-medium;
  }

  .addWorkflowButton {
    display: none;
  }

  &:hover {
    .noWorkflow {
      display: none;
    }
    .addWorkflowButton {
      display: inline-block;
    }
  }
}
.workflowText {
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .name {
    display: flex;
    align-items: center;
    @include font-text-tiny;
    .icon {
      fill: black;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      margin-top: 1px;
    }
    .successIcon {
      fill: $accent;
      width: 16px;
      height: 16px;
      margin-right: 3px;
      margin-top: 1px;
    }
    &.completed {
      color: $success;
      @include font-text-tiny-bold;
    }
  }
  .terminateWorkflowButton {
    display: none;
    margin-top: 22x;
  }
  &:hover {
    .terminateWorkflowButton {
      display: inline-block;
    }
  }
}

.icon {
  fill: $grey-medium;
  width: 12px;
  height: 15px;
}

.workflowText {
  @include font-text-tiny;
}
