@import '~styles/vars';

.container {
  padding: 20px 40px 20px 40px;
  background-color: $white;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    @include font-section-title;
    color: $grey-dark;
  }

  .closeIcon {
    cursor: pointer;
    &:hover {
      fill: $grey-medium;
    }
  }
}

.filmStrip {
  display: flex;
  flex-direction: row-reverse;
  overflow-x: scroll;

  .filmGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .observations {
      display: flex;
      flex-direction: row-reverse;
    }

    .cultureSpan {
      position: sticky;
      top: 0;
      left: 0;
    }

    .cultureName {
      @include font-info;
      color: $grey-medium;
      padding-left: 24px;
    }
  }

  .filmItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    .observationTime {
      @include font-label;
      display: grid;
      place-content: center;
      color: $grey-dark;
      height: 22px;
    }

    .timeline {
      display: grid;
      place-content: center;
      color: $accent;
      height: 10px;
    }

    .observationData {
      @include font-label-bold;
      display: grid;
      place-content: center;
      color: $accent;
      height: 22px;
    }

    .imagery {
      height: 208px;
      width: 208px;
      padding: 3px;
    }

    .selected {
      border: 2px solid $accent;
      border-radius: 5px;
      background-color: $accent-bg;
    }

    .arrow {
      width: 40px;
      height: 212px;
      display: grid;
      place-content: center;

      .arrowIcon {
        fill: $grey-dark;
        width: 24px;
        height: 24px;
      }
    }
  }
}
