@import "~styles/vars";

.submitStep {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex: 1 1 0;
  min-height: 0;

  .successNotification {
    margin-bottom: 10px;
  }

  .title {
    @include font-section-title;
    color: $grey-dark;
  }

  .contents {
    height: 100%;

    .text {
      @include font-info;
      color: $grey-dark;
      margin-top: 40px;
    }

    .operatorActionLinks {
      display: flex;
      flex-direction: column;
      margin-left: -20px;
      margin-top: 10px;
    }
  }
}

.submitError {
  margin-top: 30px;
  width: 400px;
}

.loadingMessageBox {
  width: 400px;
  padding: 30px 0;
  margin-top: 30px;
}
