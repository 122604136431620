@import '~styles/vars';

.routineList {
  flex: 1 1 0;
  min-height: 0;

  .estimatedTime {
    font-style: italic;
    color: $grey-medium;
  }

  .empty {
    color: grey;
    margin-bottom: 2px;
  }
}

.bigMessage {
  margin-top: 15px;
  color: $grey-medium;
}
