@import '~styles/vars';

.liveStatus {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;

  .tile {
    margin-bottom: 30px;
    width: 380px;
    height: 400px;
  }
}
