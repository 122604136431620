@import '~styles/vars';

.operatorToSchedule {
  color: $grey-medium;
  @include font-small-info;
}
.scheduledForWithRightPadding {
  margin-right: 2px;
}
.reviewReason {
  color: $error;
  @include font-small-info;
}
.routineName {
  display: flex;
  align-items: center;

  .workflowIcon {
    margin-right: 7px;
    margin-top: 4px;
  }
}
.userOverrideIconContainer {
  margin-left: 5px;
  height: 14px;
}
.userOverrideIcon {
  height: 14px;
  width: 14px;
  fill: $grey-medium;
}
