@import '~styles/vars';

.liveCultures {
  display: flex;
  flex-direction: column;

  .innerHeader {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .title {
      @include font-subtitle;
      color: $grey-medium;
    }

    .spacer {
      margin-left: auto;
    }

    .legend {
      height: 100px;
      width: 320px;
    }
  }

  .label {
    @include font-label-small-bold;
    color: $grey-medium;
    margin-top: 10px;
    text-align: center;
  }

  .grid {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-between;

    .gridItem {
      width: 113px;
      .plateName {
        @include font-label-bold;
        color: $grey-dark;
        margin-bottom: 5px;
      }
    }
  }

  .histogram {
    height: 50px;
    width: 320px;
  }
}
