@import '~styles/vars';

.header {
  padding: 30px 30px 30px 30px;
}

.dialogBody {
  width: 750px;
}

.image {
  margin: auto;
  width: 750px;
  height: 535px;
  background-color: $off-white;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 20px;
}

.imageMetadata {
  display: flex;
  align-items: center;
}

.imageCounter {
  // TODO: This is the closest to the mock but still not exact
  @include font-text;
  font-weight: bold;
  color: #666666;
}

.imageMetadataSubtext {
  @include font-label;
  color: #999999;
  margin-top: 2px;
}

.cultureImportant {
  @include font-text;
  font-weight: bold;
  color: #666666;
}

.cultureSubtext {
  @include font-small-info;
  color: #999999;
  margin-top: 2px;
  text-align: right;
}

.highlight {
  color: #2cb1bc;
}

.imageGridContainer {
  height: 70px;
  width: 70px;
  margin-right: 20px;
  border: 1px solid #cccccc;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageGrid {
  margin-left: 4px;

  display: flex;
  flex-direction: column;
}

.imageGridRow {
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
}

.imageGridImage {
  width: 12px;
  height: 9px;
  margin: 3px 3px 0 0;
  cursor: pointer;
}

.imageGridImage__selected {
  background-color: $accent;

  &:hover {
    background-color: lighten($accent, 20%);
  }
}

.imageGridImage__unselected {
  background-color: $grey-light;

  &:hover {
    background-color: $grey-lightest;
  }
}
