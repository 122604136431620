@import '~styles/vars';

.localInstrumentsDashboard {
  width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .type {
    @include font-label;
    color: $grey-dark;
  }

  .table {
    flex: 1 1 0;
    min-height: 0;
  }

  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-bottom: 40px;
    color: $grey-medium;
  }

  .header {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .title {
      @include font-title;
      color: $grey-dark;
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
