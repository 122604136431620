@import "~styles/vars";

.teachTool {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: -40px;

  .header {
    margin-top: 30px;
  }

  .teachToolOptions {
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 30px;

    .choice {
      width: 175px;
    }
  }
}
