@import '~styles/vars';

@layer defaults {
  .d3Chart {
    .axis {
      path {
        stroke: $grey-medium;
      }

      text {
        color: $grey-medium;
      }

      :global(.tick) {
        line {
          stroke: $grey-medium;
        }
      }
    }

    .axisLabel {
      @include font-chart-label;
      fill: $grey-medium;
    }
  }
}
