@import '~styles/vars';

.ot2TransferStationViz {
  border: 1px solid $grey-lightest;
  padding: 5px;
  width: max-content;

  .row {
    display: flex;

    .cell {
      margin: 3px;
    }
  }
}
