@import '~styles/vars';

.globalConfigEditDialog {
  width: 600px;

  .title {
    @include font-section-title;
    margin-bottom: 10px;
  }
}
