@import '~styles/vars';

.culturePage {
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 12px;
  }

  .tabs {
    margin-bottom: 10px;
  }
}
