@import "~styles/vars";

.instrumentNameWithStatus {
  display: inline-block;

  &.normal {
    @include font-subtext;

    &.warning {
      @include font-subtext-bold;
    }

    &.error {
      @include font-subtext-bold;
    }
  }

  &.big {
    @include font-title;
  }

  &.warning {
    color: $warning;
  }

  &.error {
    color: $error;
  }
}

.popover {
  padding: 10px 20px 8px;
  max-width: 400px;

  .header {
    @include font-label-bold;
    margin-bottom: 5px;

    &.warning {
      color: $warning;
    }

    &.error {
      color: $error;
    }
  }

  .message {
    @include font-subtext;
  }
}
