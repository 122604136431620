@import '~styles/vars';

.processStepTable {
  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    margin-bottom: 40px;
    color: $grey-medium;
  }

  .instrumentName {
    @include font-label;
    color: $grey-medium;
  }
  .commandName {
    @include font-subtext;
  }
}
