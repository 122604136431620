@import '~styles/vars';

.table {
  &.flex {
    display: flex;
    flex-direction: column;
  }

  &.flexAuto {
    display: flex;
    flex-direction: column;

    .rows {
      flex: 1 1 auto;
    }
  }

  &.flushLeft {
    margin-left: -10px;

    .noDataMessage {
      margin: 0;
    }
  }

  .checkbox {
    width: 30px;
  }

  .headers {
    @include font-label;
    display: flex;
    color: $grey-medium;
  }

  .noDataMessage {
    @include font-subtext;
    color: $grey-medium;
  }

  .rows {
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
  }

  .row {
    @include font-subtext;
    display: flex;
    border-top: 1px solid $grey-lightest;
    align-items: center;

    &.hideRowBorders {
      border: none;
    }

    &.withOnClick {
      cursor: pointer;
    }

    &:hover {
      background-color: $off-white;
    }

    .innerLink {
      flex: 1 1 0;
      min-width: 0;
      display: flex;
      align-items: center;
    }

    &:hover {
      .cell.showOnHover {
        opacity: 1;
      }
      .cellContents.showOnHover {
        display: block;
      }
      .cellContents.hideOnHover {
        display: none;
      }
    }
  }

  .cell {
    padding: 10px 10px 10px 0;
    word-wrap: break-word;
  }

  .cellContents {
    &.showOnHover {
      display: none;
    }
  }

  .cell,
  .header {
    &.smallText {
      @include font-text-tiny;
      color: $grey-dark;
    }

    &.rightAlign {
      text-align: right;
      padding-right: 5px;
    }

    &.omitCellPadding {
      padding: 0;
    }

    &.showOnHover {
      opacity: 0;
    }

    &.verticalCenter {
      display: flex;
      align-items: center;
    }
  }

  .infiniteloading {
    @include font-text-tiny;
    border-top: 1px solid $grey-lightest;
    text-align: center;
    color: $grey-medium;
    padding: 10px 20px;
  }

  &.rowPaddingLow {
    .headers {
      padding: 20px 10px 10px;
    }

    .noDataMessage {
      padding: 20px 10px 10px;
    }

    .row {
      padding: 0 10px;
    }
  }

  &.rowPaddingHigh {
    .headers {
      padding: 20px 80px 10px;
    }

    .noDataMessage {
      padding: 20px 80px 10px;
    }

    .row {
      padding: 0 80px;
    }
  }
}
