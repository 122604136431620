@import '~styles/vars';

.editableProcessItemViz {
  width: 75px;
  height: 50px;

  .inner {
    position: relative;
    width: 75px;
    height: 50px;

    .overlay {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;

      &.show {
        opacity: 1;
      }
    }

    .overlayMessage {
      @include font-label-bold;
      display: flex;
      align-items: center;
      color: white;

      .icon {
        width: 20px;
        height: 20px;
        fill: white;
      }
    }
  }
}
