@import "~styles/vars";

.processItemPopover {
  padding: 10px;
  width: 350px;

  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 2px;
  }

  .item {
    @include font-subtext-bold;
    color: $grey-dark;
    word-break: break-all;
  }

  .empty {
    font-style: italic;
    color: $grey-light;
  }

  .itemViz {
    margin-top: 4px;
  }

  .itemMetadata {
    @include font-text-tiny;
    color: $grey-medium;
    font-style: italic;
  }

  .editButton {
    margin-top: 10px;
  }

  .info {
    @include font-text-tiny;
    margin-top: 4px;
    color: $grey-medium;
    font-style: italic;
    word-break: break-all;
  }

  .experiments {
    @include font-text-tiny;
    margin-top: 4px;
  }

  .emptyWarning {
    @include font-text-tiny-bold;
    margin-top: 2px;
    color: $error;
  }

  .itemStatusPill {
    margin-top: 2px;
  }
}
