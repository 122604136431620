@import '~styles/vars.scss';

.cultureProcessLogView {
  display: flex;
  flex-direction: column;
  height: 100%;

  .controls {
    padding: 10px 0;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    .fill {
      flex: 1 1 0;
    }

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .eventKindLabel {
    @include font-label;
    color: #999999;
    text-transform: uppercase;
  }

  .eventDetailsLabel {
    @include font-small-info;
    color: $grey-medium;
  }

  .eventName {
    @include font-subtext-bold;
    margin-bottom: 3px;
  }

  .rowText {
    color: #666666;
  }

  .table {
    flex: 1 1 0;
    min-height: 0;
  }
}
