@import '~styles/vars';

.sectionTitle {
  display: flex;
  align-items: center;
  @include font-section-title;
  color: $grey-dark;
  margin-bottom: 10px;
  margin-top: 15px;

  .workflowsIcon {
    fill: $grey-dark;
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }
}

.info {
  @include font-small-info;
  color: $grey-medium;
  margin-bottom: 15px;
}
