@import '~styles/vars';

.consumableReqsTable {
  .text {
    @include font-subtext;
    color: black;
    margin-bottom: 2px;
  }
  .subtext {
    @include font-text-tiny;
    color: $grey-medium;
  }
}
