@import '~styles/vars';

.statusLabel {
  @include font-label-bold;
  color: $grey-dark;
}

.status {
  @include font-label-bold;
  color: $grey-dark;

  &.status__active {
    color: $success;
  }

  &.status__consumed {
    color: $grey-medium;
  }

  &.status__terminated {
    color: $error;
  }
}
