@import '~styles/vars';

.loadingMessage {
  display: flex;
  align-items: center;

  .icon {
    fill: $grey-medium;
  }

  .message {
    color: $grey-medium;
  }

  &.black {
    .icon {
      fill: black;
    }

    .message {
      color: black;
    }
  }

  &.accent {
    .icon {
      fill: $accent;
    }

    .message {
      color: $accent;
    }
  }

  &.default {
    .message {
      @include font-label;
      margin-left: 10px;
    }

    .icon {
      width: 12px;
      height: 15px;
    }
  }

  &.large {
    flex-direction: column;

    .message {
      @include font-text;
      margin-top: 5px;
      text-align: center;
    }

    .icon {
      width: 16px;
      height: 20px;
    }
  }
}
