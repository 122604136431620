@import '~styles/vars';

.histogram {
  width: 270px;
  height: 50px;
  margin-right: 50px;
}
.histogramLegend {
  width: 270px;
  height: 25px;
}

.koScoreHistogramLegendBlock {
  height: 5px;
  position: relative;
  background-color: $grey-lightest;

  .heterozygous {
    background-color: $cle-demo-blue;
    position: absolute;
    height: 100%;
  }

  .homozygous {
    background-color: $cle-demo-purple;
    position: absolute;
    height: 100%;
  }
}

.labels {
  position: relative;
  margin-top: 4px;

  .label {
    position: absolute;
    @include font-label;
    color: $grey-medium;
  }

  .leftLabel {
    left: 0px;
  }
  .midLabel {
    width: 20px;
    text-align: center;
    left: calc(50% - 10px);
  }
  .rightLabel {
    right: 0px;
  }
}
