// Colors
$primary: #323f4b;
$primary-bg: #e4e7eb;
$grey-darkest: #333;
$grey-dark: #666;
$grey-medium: #999;
$grey-light: #ccc;
$grey-lightest: #eaeaea;
$off-white: #fafafa;
$white: #fff;
$accent: #2cb1bc;
$accent-dark: #14919b;
$accent-bg: #e0fcff;
$accent-bg-viz-empty: #e3f7f9;
$accent-bg-viz: #c0f4fa;
$accent-light: #80d0d8;
$success: #199473;
$success-bg: #effcf6;
$error: #d64545;
$error-dark-bg: #facdcd;
$error-bg: #ffeeee;
$warning: #d48206;
$warning-dark-bg: #fff3c4;
$warning-bg: #fffbea;
// For tubes with caps in MeasuredVolumes.
$cap-border: #f9703e;
$cap-bg: #ffb088;
// For sample storage check out
$check-out: #f35627;
$check-out-bg: #ffe8d9;
// For sample storage check in
$check-in: #40c3f7;
$check-in-bg: #e3f8ff;
// For process mapping.
$blue: #2186eb;
$blue-light: #7cc4fa;

$box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
$box-shadow-lite: 0 1px 1px rgba(0, 0, 0, 0.1);

$cle-demo-blue: #3994c1;
$cle-demo-blue-bg: #ebf8ff;
$cle-demo-blue-light: #a7d8f0;
$cle-demo-purple: #8662c7;
$cle-demo-purple-bg: #eae2f8;
$cle-demo-purple-light: #cfbcf2;

$categorical_0: #628945;
$categorical_1: #af8941;
$categorical_2: #b05d46;
$categorical_3: #14919b;

// The distance between adjacent control elements, such as inputs and selects.
$controls-margin: 10px;

@mixin font-text {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
}

@mixin font-text-bold {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  font-weight: bold;
}

@mixin font-subtext {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-weight: normal;
}

@mixin font-subtext-semibold {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-weight: 600;
}

@mixin font-subtext-bold {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-weight: bold;
}

@mixin font-info {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-style: italic;
}

@mixin font-title {
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 1px;
}

@mixin font-title-bold {
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 1px;
  font-weight: bold;
}

@mixin font-subtitle {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1px;
}

@mixin font-section-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 1px;
}

@mixin font-label {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin font-small-info {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.4px;
  font-style: italic;
}

@mixin font-button-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}

@mixin font-label-bold {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
}

@mixin font-chart-label {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
}

@mixin font-label-small-bold {
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
}

@mixin font-text-tiny {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.4px;
}

@mixin font-text-tiny-bold {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.4px;
  font-weight: bold;
}

// Use this sparingly.
// Currently only used for condensed 384 well plate volume display.
@mixin font-text-very-tiny {
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.4px;
}

@mixin font-monospace {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-family: monospace;
}

@mixin font-callout-small {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-weight: bold;
}

@mixin font-callout {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.4px;
  font-weight: bold;
}

@mixin full-min-height {
  min-height: 100vh;

  @media screen and (max-width: $full-screen-max-width) {
    min-height: calc(100vh - 40px);
  }
}

@mixin min-height {
  height: 100vh;

  @media screen and (max-width: $full-screen-max-width) {
    height: calc(100vh - 40px);
  }
}

@mixin one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

$left-nav-width: 200px;
$full-screen-max-width: 1320px;

// Apply this to a div element to create a border.
// The dashes and the space between them are currently the same width.
// Update "50%" below to change this.
// The -($dash-width/2 starts the left of the border at the "middle" of the first dash,
// which works well visually if the border is a multiple if 2 * dash-width.
@mixin horizontal-border($color, $dash-width, $border-height) {
  height: $border-height;
  background-image: linear-gradient(to right, $color 50%, white 0%);
  background-position: -($dash-width/2) 0%;
  background-size: $dash-width * 2 $border-height;
  background-repeat: repeat-x;
}
