@import "~styles/vars";

.plateMapHoverText {
  @include font-subtext;
  color: $accent;
  text-decoration: underline dashed $accent;
}

.plateMapPopover {
  padding: 30px;

  .experimentPlateViz {
    margin-top: 20px;
  }

  .name {
    @include font-subtext-bold;
    color: $grey-darkest;
  }

  .legendSquare {
    width: 17.5px;
    height: 17.5px;

    &.primary {
      background-color: $primary;
    }
    &.accent {
      background-color: $accent;
    }
    &.warning {
      background-color: $warning;
    }
  }

  .legend {
    margin-top: 30px;
  }

  .legendRow {
    display: flex;
    margin: 5px 0;

    .text {
      @include font-subtext;
      margin-left: 5px;
      color: $grey-medium;
    }
  }
}
