@import "~styles/vars";

.accordion {
  &.default {
    .header {
      color: $grey-dark;
    }
    .icon {
      fill: $grey-dark;
    }
  }
  &.error {
    .header {
      color: $error;
    }
    .icon {
      fill: $error;
    }
  }
  &.warning {
    .header {
      color: $warning;
    }
    .icon {
      fill: $warning;
    }
  }

  .header {
    @include font-subtext;
    background-color: white;
    display: flex;
    align-items: center;

    &.toggleable {
      cursor: pointer;
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    &:hover {
      background-color: $off-white;
    }
  }

  .toggleContainer {
    width: 12px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 12px;
      height: 12px;
      transition: transform 250ms;

      &.open {
        transform: rotate(90deg);
      }
    }
  }
}
