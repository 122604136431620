@import "~styles/vars";

.tabularDataSchemaDisplay {
  .property {
    @include font-subtext;
    color: $grey-dark;
    margin-bottom: 5px;

    .propertyName {
      @include font-subtext-bold;
    }
  }
  .example {
    .exampleTitle {
      @include font-subtext-bold;
      color: $grey-dark;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    .exampleValue {
      @include font-monospace;
      padding: 15px 20px;
      font-size: 14px;
      word-break: break-word;
      border: 1px solid $grey-light;
      white-space: pre-wrap;
    }
  }
}
