@import '~styles/vars';

.tecanEvoInstrumentPage {
  height: 100%;

  .genericInstrumentPage {
    height: 100%;
  }

  .evowareErrorStatus {
    .noError{
      @include font-label-bold;
      color: $success;
    }

    .hasError{
      @include font-label-bold;
      color: $error;
    }
  }

  .bodyContents {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 34px;

    .label {
      @include font-label;
      margin-top: 10px;
      margin-bottom: 5px;
      color: $grey-medium;
    }

    .consumables {
      display: flex;

      .uuid {
        @include font-subtext;
      }
    }

    .processItemTable {
      flex: 1 1 0;
      overflow: auto;
    }
  }
}
