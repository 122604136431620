@import '~styles/vars';

.pill {
  @include font-label-bold;
  color: $grey-medium;
  background-color: $off-white;
  border: 1px solid $grey-medium;
  border-radius: 8px;
  height: 20px;
  display: inline-block;
  padding: 2px 10px;

  &.success {
    background-color: $success-bg;
    color: $success;
    border: 1px solid $success;
  }

  &.error {
    background-color: $error-bg;
    color: $error;
    border: 1px solid $error;
  }

  &.warning {
    background-color: $warning-bg;
    color: $warning;
    border: 1px solid $warning;
  }

  &.info {
    background-color: $primary-bg;
    color: $primary;
    border: 1px solid $primary;

    &.inverted {
      border: none;
      color: white;
      background-color: $primary;
    }
  }

  &.inactive {
    color: $grey-medium;
    background-color: $grey-lightest;
    border: 1px solid $grey-lightest;
  }

  &.accent {
    background-color: $accent-bg;
    color: $accent;
    border: 1px solid $accent;
  }

  &.small {
    @include font-label-small-bold;
    height: 16px;
    border-radius: 6px;
    padding: 1px 6px;
  }

  &.checkOut {
    background-color: $check-out-bg;
    color: $check-out;
    border: 1px solid $check-out;
  }

  &.checkIn {
    background-color: $check-in-bg;
    color: $check-in;
    border: 1px solid $check-in;
  }

  &.cleDemoBlue {
    background-color: $cle-demo-blue-bg;
    color: $cle-demo-blue;
    border: 1px solid $cle-demo-blue;
  }

  &.cleDemoPurple {
    background-color: $cle-demo-purple-bg;
    color: $cle-demo-purple;
    border: 1px solid $cle-demo-purple;
  }
}
