@import '~styles/vars';

@mixin colors($icon-stroke, $icon-fill, $message-color) {
  .icon {
    stroke: $icon-stroke;
    fill: $icon-fill;
  }

  .message {
    color: $message-color;
  }
}

.tinyNotification {
  display: flex;
  align-items: flex-start;

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .message {
    @include font-text-tiny;
    flex: 1 1 0;
    min-width: 0;
    word-break: break-word;
  }

  &.info {
    @include colors($grey-medium, none, $grey-medium);
  }

  &.warning {
    @include colors($warning, none, $warning);
  }

  &.success {
    @include colors(none, $success, $success);
  }

  &.error {
    @include colors($error, none, $error);
  }

  &.bareInfo {
    @include colors(none, $grey-medium, $grey-medium);

    .icon {
      margin-right: 2px;
      margin-left: -6px;
    }
  }

  &.bareWarning {
    @include colors(none, $warning, $warning);

    .icon {
      margin-right: 2px;
      margin-left: -6px;
    }
  }

  &.bareSuccess {
    @include colors(none, $success, $success);

    .icon {
      margin-right: 2px;
      margin-left: -2px;
    }
  }

  &.bareError {
    @include colors(none, $error, $error);

    .icon {
      margin-right: 2px;
      margin-left: -6px;
    }
  }

  &.loading {
    @include colors(none, $accent, $accent);

    .icon {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}
