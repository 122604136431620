@import '~styles/vars';

.processItemTable {
  display: flex;
  flex-direction: column;
  height: 100%;

  .processItem {
    margin: 10px 0;
  }

  .metadata {
    font-style: italic;
  }

  .table {
    flex: 1 1 0;
    min-height: 0;

    .icon {
      fill: $grey-medium;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        fill: $grey-dark;
      }
    }
  }

  .workflowsCompletedNotification {
    width: 400px;
  }
}

.bigMessage {
  display: flex;
  justify-content: center;
  color: $grey-medium;
  margin-top: 50px;
}
