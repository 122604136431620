@import '~styles/vars';

.workflowInstances {
  display: flex;
  flex-direction: column;
  height: 100%;

  .workflowsInstanceTable {
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
  }
}
