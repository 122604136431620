@import '~styles/vars';

.container {
  border-top: 2px solid $grey-light;
  padding-top: 6px;
}

.title {
  @include font-subtext;
  margin-bottom: 18px;
  color: $grey-darkest;
}

.graph {
  height: 230px;

  svg {
    overflow: visible;
  }

  .xAxisBorder {
    stroke: $grey-medium;
  }

  .xTickLabels {
    @include font-label-bold;
    transform: translateY(4px);
  }

  .yAxisBorder {
    display: none;
  }

  .yTickLines {
    stroke: $grey-lightest;
    stroke-width: 1px;
  }

  .yTickLabels {
    @include font-label-small-bold;
    text-anchor: start;
    transform: translate(2px, 9px);
  }
}

.legend {
  margin-bottom: 26px;
  display: grid;
  grid-template-columns: 40px max-content max-content;
  gap: 12px;
  align-items: center;

  .name {
    @include font-label-bold;
  }

  .value {
    @include font-subtext-semibold;
  }
}
