.liveCulturesFilters {
  margin-top: 30px;
  display: flex;
}

.selector {
  margin-right: 42px;
  // Same width as Culture Status Filter
  width: 270px;

  .input {
    width: 100%
  }
}
