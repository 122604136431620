@import '~styles/vars';
.futureRoutineQueueFailPopover {
  width: 130px;
}
.trigger {
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 128px;

  .icon {
    fill: $warning;
    width: 14px;
    height: 14px;
    margin-left: -5px;
  }
  .text {
    @include font-label-bold;
    color: $warning;
  }
}

.popover {
  @include font-subtext;
  max-width: 350px;
  padding: 20px;
  color: $grey-dark;
  .info {
    @include font-small-info;
    color: $grey-medium;
  }
}
