@import "~styles/vars";

.loadUnloadItemsLocationSelection {
  &.disabled {
    .locationSelection {
      background-color: $off-white;
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .label {
      @include font-label;
      color: $grey-medium;
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .allowArmAssist {
      justify-self: flex-end;
    }
  }

  .locationSelection {
    border: 1px $grey-light solid;
    padding-bottom: 20px;

    .rotateShelfButton {
      padding-left: 68px;
    }

    .instrumentSelectTrigger {
      width: 378px;
    }
    .instrumentSelectTriggerIcon {
      margin-right: 5px;
    }
    .instrumentViz {
      margin-top: 10px;
    }
    .loadSelectContainer {
      padding: 0 40px 0 68px;
    }
    .armAssistStorageMessage {
      padding: 5px 40px 0 68px;
    }
    .instrumentVizContainer {
      margin: 10px 40px 10px 68px;
      min-height: 0;
      overflow: auto;
    }
  }
}

.instrumentSelectPopover {
  width: 378px !important;
}
