@import '~styles/vars';

.icon {
  fill: $grey-light;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: $off-white;
  }
}

.content {
  padding: 5px 0;

  .option {
    padding: 10px 20px;
    cursor: pointer;
    display: block;

    &.disabled {
      color: $grey-medium;
      cursor: inherit;
    }

    &:not(.disabled):hover {
      background-color: $off-white;
    }
  }
}
