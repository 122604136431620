@import "~styles/vars";

.processItem {
  .empty {
    font-style: italic;
    color: $grey-medium;
    margin-bottom: 5px;
  }

  .itemName {
    margin-bottom: 5px;
  }

  .unloadAction {
    @include font-label-bold;
    color: $accent;
    cursor: pointer;
  }
}
