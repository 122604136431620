@import '~styles/vars';

.instrumentSelect {
  //Placeholder
  padding: 0;

  .trigger {
    width: 300px;
  }
}

.popover {
  width: 300px;
}
