@import '~styles/vars';

.clePipelineViz {
  display: flex;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 70px;
  justify-content: space-around;

  .clePipelinePhase {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
    &.disabled {
      .cellLineCountContainer {
        .cellLineCount {
          background-color: $grey-light;
        }
        .alertCount {
          background-color: $grey-light;
        }
      }
      .phase {
        color: $grey-light;
      }
    }
    &:hover {
      .cellLineCountContainer {
        .cellLineCount {
          background-color: lighten($accent, 20%);
        }
        .alertCount {
          background-color: lighten($warning, 20%);
        }
      }
      .phase {
        color: $grey-medium;
      }
    }

    &:last-child {
      margin-right: 0;
      .dottedLine {
        display: none;
      }
    }

    .cellLineCountContainer {
      position: relative;
      width: 60px;
      height: 60px;

      .cellLineCount {
        width: 60px;
        height: 60px;
        background-color: $accent;
        @include font-subtitle;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
      }
      .alertCount {
        position: absolute;
        top: -15px;
        right: -15px;
        width: 30px;
        height: 30px;
        background-color: $warning;
        @include font-subtext-bold;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
      }
      .dottedLine {
        border-bottom: 2px dashed $grey-light;
        width: 180px;
        position: absolute;
        left: 93px;
        top: 50%;
      }
    }

    .phase {
      color: $grey-dark;
      @include font-subtitle;
      margin-top: 7px;
      cursor: pointer;
    }
  }
}
