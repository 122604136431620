@import '~styles/vars.scss';

.microplatePlayground {
  .contents {
    margin: 20px 80px;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 450px;
      padding-right: 20px;
      height: 250px;

      .label {
        @include font-subtext;
        color: $grey-medium;
        margin-bottom: 10px;
      }
    }

    .plate {
      margin-bottom: 10px;
    }
  }
}
