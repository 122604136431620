@import '~styles/vars';

.processItemLocation {
  .instrumentName {
    @include font-subtext;
    color: black;
    margin-bottom: 2px;
  }
  .location {
    @include font-text-tiny;
    color: $grey-medium;
  }
}
