@import '~styles/vars';

.futureRoutines {
  display: flex;
  flex-direction: column;
  height: 100%;

  .routineList {
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
  }

  .footer {
    border-top: 1px solid $grey-lightest;

    .controls {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;

      &.withNotification {
        margin-top: 8px;
      }

      .fill {
        flex: 1 1 0;
      }
    }

    .button {
      width: 250px;
    }

    .cancelButton {
      margin-left: 20px;
    }

    .editSchedulePopover {
      margin-left: 20px;
    }

    .notification {
      margin-top: 15px;
    }
  }
}
