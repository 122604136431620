@import '~styles/vars';

.d3Histogram {
  shape-rendering: crispEdges;
}

.axis {
  path {
    stroke: $grey-medium;
  }

  text {
    color: $grey-medium;
  }

  :global(.tick) {
    line {
      stroke: $grey-medium;
    }
  }
}

.axisLabel {
  @include font-chart-label;
  fill: $grey-light;
}

.bar {
  fill: $grey-lightest;

  &.highlight {
    fill: $error;
  }
}

.hoverBar {
  cursor: pointer;

  &:hover {
    fill: rgba(0, 0, 0, 0.05);
  }

  &.highlight {
    fill: rgba(255, 0, 0, 0.1);

    &:hover {
      fill: rgba(255, 0, 0, 0.2);
    }
  }
}
