@import "~styles/vars";

.successStep {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex: 1 1 0;
  min-height: 0;

  .successNotification {
    margin-bottom: 10px;
  }

  .contents {
    display: flex;
    height: 100%;
  }

  .dataTableColumn {
    width: 550px;
    margin-right: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .dataTable {
    flex: 1 1 0;
    min-width: 0;
    overflow: auto;
  }
}
