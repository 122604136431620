@import '~styles/vars';

.textCheckbox {
  @include font-label-bold;
  cursor: pointer;
  text-decoration: underline;

  &.warning {
    color: $warning;
  }
  &.error {
    color: $error;
  }
  &.accent {
    color: $accent;
  }
  &.success {
    color: $success;
  }
}
