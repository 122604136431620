@import '~styles/vars';

.monitorRoutes {
  width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .tabPanel {
    height: 100%;
  }

  .tabs {
    margin-top: 5px;
  }
}

.routeErrorContainer {
  margin: 2em 3em;
  @include font-text;
}
