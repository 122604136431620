@import '~styles/vars';

.nestControls {
  .controlLabel {
    color: $grey-dark;
    @include font-subtext-bold;
    margin-bottom: 5px;
  }

  .select {
    width: 300px;
  }

  .trigger {
    width: 300px;
  }

  .nestLabel {
    margin-top: 25px;
    margin-bottom: 5px;
    @include font-label;
    color: $grey-medium;
  }

  .nestApproachPathLabel {
    margin-top: 25px;
    @include font-label;
    color: $grey-medium;
    text-decoration: underline;
  }

  .selectedNest {
    margin-top: 10px;
    .noNest {
      @include font-info;
      color: $grey-medium;
    }
  }

  .nestButtons {
    margin-top: 15px;
    display: flex;
    column-gap: 15px;

    .saveButton {
      background-color: $accent;
      color: $white;
    }
  }

  .nestLocationText {
    @include font-subtext;
    color: $grey-dark;
    word-spacing: 5px;
  }
  .nestLocationTextWithHighlight {
    @include font-subtext;
    color: $accent;
    word-spacing: 5px;
  }

  .seqFilePath,
  .nestApproachPath {
    @include font-small-info;
    color: $grey-medium;
    text-decoration: underline dashed $grey-medium;
    cursor: pointer;
  }

  .noNestApproachPath {
    @include font-small-info;
    color: $grey-medium;
    margin-top: 20px;
  }

  .nestApproachPath {
    margin-top: 20px;
    display: inline-block;
  }
}
.popover {
  width: 300px;
}

.seqFilePathPopover {
  @include font-text-tiny;
  max-width: 400px;
  padding: 20px;
  word-wrap: break-word;
  color: $grey-dark;
}

.nestApproachPathPopover {
  @include font-text-tiny;
  padding: 20px;
  color: $grey-dark;
}
