@import '~styles/vars';

.pastCultures {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.title {
  @include font-title;
  height: 65px;
  display: flex;
  align-items: center;
  color: $grey-dark;
  margin-bottom: 30px;
}

.tableTitle {
  // Use the same styling as the live_cultures page.
  @include font-subtitle;
  display: flex;
  align-items: center;
  color: $grey-medium;
  margin-top: 35px;
}

.outerTableContainer {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
}

.innerTableContainer {
  display: flex;
  flex: 1 1 0;
}

.cultureLink {
  margin-top: 12px;
  margin-bottom: 12px;
}

.lineGraph {
  height: 50px;
  width: 70px;
}

.thumbnail {
  width: 85px;
  height: 55px;
}
