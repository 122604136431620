@import '~styles/vars';

.futureRoutinesHeader {
  display: flex;

  .mainHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .toggleDisplayMode {
      margin-top: 2px;
    }

    .title {
      display: flex;
      align-items: center;

      .menu {
        height: 24px;
        margin-left: 5px;
      }
    }
    .futureRoutineCount {
      @include font-subtitle;
      color: $grey-dark;
    }
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .requiresUserAttentionNotification {
    width: 540px;
  }
}
