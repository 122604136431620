@import "~styles/vars";

.sequenceValidationForm {
  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-bottom: 10px;
  }

  .trigger {
    width: 350px;
  }

  .reverseSelect {
    margin-top: 24px;
  }

  .sequenceFields {
    margin-top: 24px;
    display: flex;
  }

  .startOnStepInput,
  .storageLocationInput {
    width: 170px;

    .inner {
      width: 100%;
    }
  }

  .startOnStepInput {
    margin-right: 10px;
  }

  .validationParameters {
    margin-top: 30px;
    padding-bottom: 40px;
  }
}

.popover {
  width: 700px;
}
