@import '~styles/vars';

@import '~styles/vars';

.cleCulturePopover {
  width: 390px;
  background-color: white;
  padding: 30px 35px;

  .popoverHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;
  }

  .popoverHeaderLabel {
    @include font-label;
    color: $grey-medium;
  }

  .popoverHeaderCulture {
    @include font-subtext-bold;
    color: $accent;
  }

  .popoverHeaderMetadata {
    @include font-small-info;
    color: $grey-medium;
  }

  .popoverChart {
    width: 70px;
    height: 50px;
  }

  .popoverDataTitle {
    margin-bottom: 10px;

    .confluency {
      @include font-subtext-bold;
    }

    .capturedAt {
      @include font-small-info;
      color: $grey-medium;
    }
  }

  .thumbnailContainer {
    cursor: pointer;
  }

  .popoverImage {
    width: 320px;
    height: 200px;
    background-color: $grey-lightest;
  }

  .popoverMetadata {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0 20px;

    .popoverMetadataField {
      margin-top: 15px;
      width: calc(50% - 10px);
      color: $grey-darkest;
    }

    .popoverMetadataHighlight {
      color: $accent;
    }
  }
}
