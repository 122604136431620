@import '~styles/vars.scss';

.iconPlayground {
  .icons {
    margin: 20px 80px;
    height: 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .subtext {
    @include font-subtext;
    color: $grey-medium;
    margin: 10px 80px;
  }

  .iconContainer {
    display: flex;
    margin: 5px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .iconWithFill {
      @extend .icon;
      fill: $grey-medium;
    }

    .iconWithStroke {
      @extend .icon;
      stroke: $grey-medium;
    }

    .name {
      margin-left: 5px;
      color: $grey-medium;
    }
  }
}
