@import "~styles/vars";

.plateMapSelect {
  margin-top: 15px;
}
.trigger {
  width: 350px;
}
.popover {
  width: 350px;
}
.labwareSelect {
  margin-top: 15px;
}
