@import "~styles/vars";

.bulkExportCultureMetadataPreview {
  display: flex;
  flex-direction: column;

  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-bottom: 10px;
  }

  .info,
  .errorCount {
    @include font-info;
    color: $grey-dark;
  }
  .contents {
    flex: 1 1 0;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1 1 0;
      min-height: 0;
      overflow: auto;
      color: $grey-dark;
    }
  }
}
