@import '~styles/vars';

.routineInstructions {
  margin: 10px 0;

  .instructions {
    @include font-small-info;
    color: $grey-medium;
  }
}
