@import '~styles/vars';

.checkboxOption {
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  display: flex;

  .checkbox {
    margin-right: 5px;
  }

  &.allCaps {
    @include font-label;
  }

  &.largeCheckboxRightMargin {
    .checkbox {
      margin-right: 8px;
    }
  }

  &.tinyText {
    @include font-text-tiny;
    color: $grey-medium;
  }
}
