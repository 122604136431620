@import '~styles/vars';

.inhecoScilaCloseDrawerDialog {
  .text {
    @include font-subtext;
    margin-top: 10px;
  }

  .closeDrawerMessage {
    margin-left: 4px;
  }
}
