@import "~styles/vars";

.loadingMessage {
  margin-top: 40px;
}

.liveCultures {
  padding-top: 40px;

  .liveCulturesInner {
    display: flex;
    flex-direction: column;

    .innerHeader {
      display: flex;
      align-items: center;
      margin: 30px 0 0;

      .title {
        @include font-subtitle;
        color: $grey-medium;
        // Compensate for extra spacing needed for histogram
        margin-bottom: 20px;
      }

      .spacer {
        margin-left: auto;
      }

      .legend {
        height: 100px;
        width: 320px;
      }
    }

    .label {
      @include font-label-small-bold;
      color: $grey-medium;
      margin-top: 10px;
      text-align: center;
    }

    .grid {
      margin: 15px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      justify-content: space-between;

      .gridItem {
        width: 113px;

        .plateName {
          @include font-text-tiny-bold;
          color: $grey-dark;
          margin-top: 5px;
          word-break: break-all;
        }

        &.interactive:hover {
          opacity: 0.85;
          cursor: pointer;
        }
      }
    }

    .histogram {
      height: 50px;
      width: 320px;

      .yAxis path,
      .yAxis :global(.tick) line {
        stroke: $grey-light;
      }

      .yAxis .hideTickLine {
        display: none;
      }
    }
  }
}

.avgConfluence {
  @include font-small-info;
  font-size: 13px;
  font-weight: bold;

  &.placeholder {
    color: $grey-medium;
  }
}
