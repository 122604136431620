@import '~styles/vars';

.main {
  margin: 0 40px 40px;
  display: grid;
  grid-template-columns: 1fr 330px;
  grid-template-areas:
    'header      empty1'
    'heroToolbar empty2'
    'hero        sidebar';
  column-gap: 24px;
}

.titleArea {
  grid-area: header;
  height: 50px;
  margin: 20px 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @include font-title;
  color: $grey-dark;
}

.status {
  margin-left: 20px;
}

.lateralNavControls {
  display: flex;
  align-items: center;
  color: $grey-dark;
  font-variant-numeric: tabular-nums; // Avoid small shifts while hovering different wells.

  .label {
    @include font-subtext-semibold;
    margin: 0 4px;
    display: none;

    &.active {
      color: $accent;
    }
  }

  &:hover .label:not(:hover) {
    display: block;
  }

  .tinyMicroplate {
    border: 0;
    background-color: transparent;
  }

  .compassContainer {
    margin-left: 16px;
  }
}

.heroToolbarArea {
  grid-area: heroToolbar;
  height: 18px;
  margin-bottom: 9px;
}

.heroToolbarContent {
  @include font-small-info;
  color: $grey-dark;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pager {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.hero {
  grid-area: hero;
}

.sidebar {
  grid-area: sidebar;
}
