@import "~styles/vars";

.bulkExportCultureMetadata {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 75px;
    display: flex;
    align-items: center;

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .headerIcon {
      width: 30px;
      height: 30px;
      fill: $grey-medium;
    }
    .headerText {
      @include font-subtitle;
      color: $grey-dark;
      margin-left: 10px;
    }

    .cancelButton {
      padding: 9px 40px;
      margin-right: 14px;
    }

    .button {
      padding: 9px 40px;
    }
  }

  .body {
    margin-top: 50px;
    display: flex;
    flex: 1 1 0;
    min-height: 0;

    .sectionTitle {
      @include font-section-title;
      color: $grey-dark;
      margin-bottom: 10px;
    }
    .inputColumn {
      width: 350px;
      margin-right: 50px;

      .textarea {
        width: 100%;
      }
    }
    .previewColumn {
      flex: 1 1 0;
      min-width: 0;

      .preview {
        height: 100%;
      }
    }
  }
}
