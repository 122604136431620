@import '~styles/vars';

.box {
  display: flex;
  align-items: center;
  border: 1px solid $grey-lightest;
  padding: 15px 20px;
  width: 600px;
}

.instrumentImage {
  max-height: 50px;
  margin-right: 15px;
}

.info {
  min-width: 0;
}

.routineHeading {
  display: flex;
  margin: 0 0 5px;
  @include font-text-bold;
}

.routineName {
  min-width: 0;
  margin-right: 14px;
  color: $grey-dark;
}

.upcomingRoutinesCount {
  flex: 0 0 auto;
  color: $grey-medium;
}

.currentStep {
  min-width: 0;
  color: $grey-medium;
  @include font-label;
}

.progress {
  margin-top: 10px;
  font-weight: bold;
}

.scheduledIndicator {
  margin-top: 5px;
  color: $warning;
  @include font-label-bold;
}
