@import '~styles/vars';

.select {
  margin-top: 30px;
}

.trigger {
  width: 300px;
}

.popover {
  width: 300px;
}
