@import '~styles/vars';

.noTime {
  color: grey;
  margin-bottom: 2px;
}
.estimatedTime {
  color: grey;
  font-style: italic;
}
