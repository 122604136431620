@import '~styles/vars';

@mixin size($factor) {
  .levelGroup {
    margin-right: 5px * $factor;
  }

  .levelLabel {
    // empirically found to scale well with shelf heights
    height: 13px * $factor;
    margin-bottom: 4px * $factor;
  }

  .levelSpacer {
    // empirically found to scale well with shelf heights
    margin-bottom: 18px;
  }

  .slot {
    margin-bottom: 4px * $factor;
  }

  .shelf {
    margin-right: 4px * $factor;
  }

  .shelfLabel {
    margin-bottom: 4px * $factor;
  }
}

.storageVizView {
  @include size(1);

  &.large {
    @include size(1.25);
  }

  &.mini {
    @include size(0.7);
  }

  &.micro {
    @include size(0.45);
  }

  .shelfGroup {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .shelf {
    &:last-child {
      margin-right: 0;
    }
  }

  .shelfLabel {
    @include font-label;
    color: $grey-medium;
    text-align: center;
  }

  .levelLabel {
    @include font-label;
    color: $grey-medium;
    text-align: right;
  }

  .noOptionsMessage {
    margin-bottom: 10px;
  }
}
