@import '~styles/vars';

.liconicInstrumentPage {
  height: 100%;

  .genericInstrumentPage {
    height: 100%;
  }

  .bodyContents {
    padding-top: 34px;
  }
}
