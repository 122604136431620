@import '~styles/vars';

.bigMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  margin-bottom: 40px;
  color: $grey-medium;
}
