@import "~styles/vars";

.textarea {
  @include font-monospace;
  padding: 6px 8px;
  border: 1px $grey-light solid;
  resize: vertical;

  &.disabled {
    color: $grey-light;
    background-color: $off-white;
  }

  &::placeholder {
    color: $grey-light;
  }
}
