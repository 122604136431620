@import '~styles/vars';

.addRemoveOperatorActionTab {
  .icon {
    width: 24px;
    height: 24px;
  }

  .select {
    margin-top: 20px;
  }

  .table {
    max-height: 200px;
  }

  .deleteIcon {
    fill: $grey-medium;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      fill: $grey-dark;
    }
  }
}
