@import '~styles/vars';

.genericInstrumentBody {
  display: flex;
  flex-direction: column;
  height: 100%;

  .view {
    flex: 1 1 0;
    min-height: 0;
  }

  .vizContainer {
    padding: 25px 0;
    overflow: auto;
    height: 100%;
  }
}
