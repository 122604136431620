@import "~styles/vars";

.recordManualPassageDataSchemasDialog {
  width: 675px;
  padding: 40px 50px;
  .title {
    @include font-subtitle;
    color: $grey-dark;
  }
  .subtitle {
    @include font-info;
    color: $grey-darkest;
    margin-top: 14px;
    max-width: 525px;
  }
  .schemaDisplay {
    margin-top: 15px;
    display: flex;
  }

  .optionTitle {
    @include font-section-title;
    margin-top: 30px;
  }
}
