@import "~styles/vars";

$wide-input-width: 300px;

.structuredDataForm {
  .field {
    margin: 5px 0;

    .key {
      @include font-subtext;
      color: $grey-dark;
      margin-top: 7px;
      flex: 1 1 0;
      min-width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 10px;
    }

    .keyLabel {
      @include font-label;
      color: $grey-medium;
      display: flex;
      align-items: center;
      height: 15px;
      margin-bottom: 5px;

      .iconContainer {
        margin-left: 2px;
      }

      .icon {
        margin-top: 4px;
        width: 12px;
        height: 12px;
        cursor: help;
        fill: $grey-medium;
      }
    }

    .checkboxContainer {
      padding: 8px 0;
    }

    .editableValue {
      .error {
        @include font-text-tiny;
        margin-top: 2px;
        color: $error;
      }
    }

    .unitFloatEditable {
      display: flex;

      .innerInputContainer {
        flex: 1 1 0;
        min-width: 0;
      }

      .innerInput {
        width: 100%;
      }

      .unit {
        @include font-text-tiny;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $off-white;
        color: $grey-medium;
        width: 80px;
        border: 1px #ccc solid;
        border-left: none;
      }
    }
  }

  &.wideValues {
    .field {
      display: flex;
    }

    .input,
    .checkboxContainer,
    .trigger,
    .unitFloatEditable,
    .dateInput,
    .editableValue .error {
      width: $wide-input-width;
    }

    .input,
    .trigger,
    .unitFloatEditable {
      @include font-monospace;
    }
  }

  &.vertical {
    .field {
      margin: 15px 0;
    }
  }
}

.popover {
  @include font-monospace;

  &.wideValues {
    width: $wide-input-width;
  }
}

.helpContent {
  @include font-subtext;
  max-width: 350px;
  padding: 20px;
  color: $grey-dark;

  .fieldName {
    @include font-text;
    margin-bottom: 10px;
    word-break: break-all;
  }

  .type,
  .description {
    margin-bottom: 5px;
  }

  .default {
    @include font-label;
    color: $grey-light;
    word-break: break-word;
  }
}
