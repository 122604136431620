@import "~styles/vars";

.uploadPlateDataStepPreview {
  display: flex;
  flex-direction: column;
  height: 100%;
  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-bottom: 5px;
  }
  .contents {
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
  }
  .info {
    @include font-info;
    color: $grey-dark;
  }
  .errorCount {
    @include font-info;
    color: $grey-dark;
  }
  .storageViz {
    margin-top: 15px;
  }
}
