@use "sass:math";

@import "~styles/vars";

@mixin cell-size(
  $well_factor,
  $size_factor,
  // Height is multipled by this ratio.
  $aspect_ratio: 1,
  // Both margins are multipled by this ratio.
  $margin_ratio: 1,
  // margin-top is multipled by this ratio.
  $margin_aspect_ratio: 1
) {
  .row {
    margin-bottom: max(
      floor(
        1px * $well_factor * $size_factor * $margin_ratio * $margin_aspect_ratio
      ),
      1
    );
  }
  .cell {
    width: floor(5px * $well_factor * $size_factor);
    height: floor(5px * $well_factor * $size_factor * $aspect_ratio);
  }
  .cellContainer {
    margin-right: max(
      floor(1px * $well_factor * $size_factor * $margin_ratio),
      1
    );
  }
}

@mixin cell-sizes($size_factor) {
  &.wells_6 {
    @include cell-size(4, $size_factor);
  }

  &.wells_12 {
    @include cell-size(
      2.6,
      $size_factor,
      $margin_ratio: 1.3,
      $margin_aspect_ratio: 0.8
    );
  }

  &.wells_24 {
    @include cell-size(2, $size_factor);
  }

  &.wells_96 {
    @include cell-size(1, $size_factor);
  }
}

.tinyMicroplate {
  @include font-text-tiny;
  border: 1px solid $grey-light;
  background-color: $off-white;
  width: 75px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .row {
    display: flex;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &.clickable {
    cursor: pointer;

    .cell {
      position: relative;

      &:hover {
        &::after {
          content: "";
          background-color: rgba(255, 255, 255, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .cellContainer {
    &:last-child {
      margin-right: 0 !important;
    }
  }

  .cell {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .icon {
      width: 24px;
      height: 24px;
      fill: $white;
    }

    &.animate {
      transition: background-color 500ms;
    }

    &.default {
      background-color: $grey-light;
    }

    &.disabled {
      background-color: $grey-lightest;
    }

    &.primary {
      background-color: $primary;
    }

    &.accent {
      background-color: $accent;
    }

    &.accentLight {
      background-color: $accent-light;
    }

    &.warning {
      background-color: $warning;
    }
    &.categorical_0 {
      background-color: $categorical_0;
    }
    &.categorical_1 {
      background-color: $categorical_1;
    }
    &.categorical_2 {
      background-color: $categorical_2;
    }
    &.categorical_3 {
      background-color: $categorical_3;
    }
  }

  @include cell-sizes(1);

  &.micro {
    width: 52px;
    height: 35px;

    @include cell-sizes(0.7);
  }

  &.medium {
    width: 120px;
    height: 81px;
    border: 2px solid $grey-light;

    @include cell-sizes(1.6);
  }

  &.large {
    width: 195px;
    height: 130px;
    border: 2px solid $grey-light;

    @include cell-sizes(2.6);
  }

  &.extraLarge {
    width: 270px;
    height: 180px;
    border: 4px solid $grey-light;
    @include cell-sizes(3.6);
  }
}
