@import '~styles/vars';

.d3LineGraph {
  .line {
    fill: none;
    stroke-width: 2px;
  }
  .intercept-line {
    fill: none;
    stroke-width: 1px;
  }

  .point {
    fill: white;
    &.hidden {
      opacity: 0;
    }
  }

  .area {
    fill: rgba($accent, 15%);
  }
}
