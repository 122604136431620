@import '~styles/vars';

.s3UploadScriptTab {
  .editorContainer {
    height: 200px;
    overflow: auto;
    padding: 10px;
    border: 1px solid $grey-medium;
  }

  .editor {
    font-family: 'Fira code', 'Fira Mono', monospace;
    font-size: 12px;
  }

  .controls {
    margin-top: 20px;

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .popoverContainer {
    margin: 10px 0 20px;
  }
}

.help {
  @include font-subtext;
  max-width: 550px;
  padding: 20px;
  color: $grey-dark;

  .caption {
    @include font-label;
    color: $grey-medium;
    margin-top: 15px;
  }

  .codeExample {
    height: 200px;
    overflow: auto;
    padding: 10px;
    border: 1px solid $grey-medium;
  }

  .editor {
    font-family: 'Fira code', 'Fira Mono', monospace;
    font-size: 12px;
  }

  ul {
    margin-left: 20px;
    margin-top: 5px;

    li {
      @include font-subtext;
    }
  }
}
