@import '~styles/vars';

.noOrg {
  @include full-min-height;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .text {
    width: 600px;
  }

  .title {
    @include font-title;
    margin-bottom: 20px;
  }

  .subtitle {
    @include font-subtitle;
    color: $grey-dark;
  }

  .link {
    color: $accent;
    font-weight: bold;

    &:hover {
      color: lighten($accent, 20%);
    }
  }
}
