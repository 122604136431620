@import '~styles/vars';

.editProcessItemDialog {
  background-color: $white;

  .info {
    @include font-small-info;
    color: $grey-medium;
    margin-top: 15px;
  }

  .processItemViz {
    margin-top: 5px;
  }

  .errorMessage {
    margin-top: 30px;
  }
}
