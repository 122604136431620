@import '~styles/vars';

.cleDemo {
  width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
