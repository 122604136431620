@import '~styles/vars';

.d3ProcessTimelineViz {
  .processLine {
    stroke: $grey-light;
  }

  .processRect {
    fill: $accent-dark;

    &.support {
      fill: $accent-light;
    }

    &.inProgress {
      fill: $warning;
    }

    &.failed {
      fill: $error;
    }
  }

  .curTimeMarker {
    stroke: $primary;
    stroke-dasharray: 4;
  }
}
