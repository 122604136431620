@import '~styles/vars';

.reagentPerformance {
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    margin-bottom: 30px;
  }

  .navigateBack {
    margin-bottom: 10px;
    @include font-text-tiny;
    cursor: pointer;
    color: $accent;

    &:hover {
      color: lighten($accent, 20%);
    }
  }

  .headerSubtext {
    @include font-label-bold;
    color: #999999;
  }

  .headerReagentName {
    @include font-subtitle;
    color: #333333;
  }

  .headerViewMetadata {
    @include font-small-info;
    color: $grey-medium;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $grey-light;
    }
  }

  .highlightMetrics {
    margin-bottom: 50px;
    display: flex;
  }

  .highlightMetric {
    min-width: 260px;
  }

  .highlightMetricLabel {
    @include font-label;
    color: #999999;
    text-transform: uppercase;
  }

  .highlightMetricValueContainer {
    margin-top: 12px;
  }

  .highlightMetricValue {
    @include font-callout;
    line-height: 40px;
  }

  .highlightMetricUnit {
    @include font-callout-small;
    text-transform: uppercase;
  }

  .metric__error {
    color: #d64545;
  }

  .metric__success {
    color: #199473;
  }

  .outerTableContainer {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
  }

  .innerTableContainer {
    display: flex;
    flex: 1 1 0;
  }

  .tableTitle {
    @include font-subtext-bold;
    color: #666666;
  }

  .cultureLink {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .mediaExchangeSubtitle {
    @include font-small-info;
    color: #999999;
  }

  .thumbnail {
    width: 85px;
    height: 55px;
  }

  .noAnomaly {
    @include font-subtext;
    font-style: italic;
    color: #999999;
  }

  .doublingTime {
    @include font-subtext-bold;
    text-transform: uppercase;
    text-align: right;
  }

  .issueLabel {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.metadataDialog {
  width: 500px;
  height: 200px;
  margin: 30px;
}

.metadataDialogHeader {
  @include font-subtext-bold;
}

.metadataDialogBody {
  display: flex;
}

.metadataFieldLabel {
  @include font-label;
  margin-top: 25px;
  margin-bottom: 4px;
  color: #999999;
}

.metadataHighlight {
  color: #2cb1bc;
}

.metadataDialogLeft {
  width: 50%;
}

.metadataDialogRight {
  width: 50%;
}

.metadataFieldValue {
  @include font-subtext;
}
