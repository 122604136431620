@import '~styles/vars';

.addInstrumentDialog {
  width: 360px;

  .title {
    @include font-section-title;
    margin-bottom: 20px;
  }

  .footer {
    margin-top: 40px;
  }

  .button {
    width: 300px;
  }
}
