@import '~styles/vars.scss';

.configureSlackDialog {
  .title {
    @include font-section-title;
  }

  .inputContainer {
    margin-top: 20px;
  }

  .input {
    width: 100%;
  }

  .channelControls {
    margin-top: 20px;

    .button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .controls {
    margin-top: 60px;
  }
}
