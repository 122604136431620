@import "~styles/vars";

.gripperStatusText {
  @include font-label;
  color: $grey-medium;

  .open {
    @include font-label-bold;
    color: $success;
  }

  .closed {
    @include font-label-bold;
    color: $error;
  }

  .unknown {
    @include font-label-bold;
    color: $grey-medium;
  }
}

.bodyStatusText {
  @include font-label;
  color: $grey-medium;

  .motorsOff {
    @include font-label-bold;
    color: $error;
  }

  .motorsOn {
    @include font-label-bold;
    color: $success;
  }

  .unknown {
    @include font-label-bold;
    color: $grey-medium;
  }
}

.teachToolStatusText {
  @include font-label;
  color: $grey-medium;

  .teachToolBusy {
    @include font-label-bold;
    color: $accent;
  }

  .teachToolReady {
    @include font-label-bold;
    color: $success;
  }
}

.teachToolButton {
  margin-top: 20px;
  width: 250px;
}
