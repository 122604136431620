@import '~styles/vars';

.uuidInput {
  width: 100%;
}
.trigger {
  width: 350px;
}
.popover {
  width: 350px;
}
.labwareSelect {
  margin-top: 15px;
}
.workflowDefinitionSelect {
  margin-top: 5px;
}
.wellSelect {
  margin-top: 15px;
}
.workflowsSectionTitle {
  display: flex;
  align-items: center;
  @include font-section-title;
  color: $grey-dark;
  margin-top: 40px;

  .workflowsIcon {
    fill: $grey-dark;
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }
}
.dateInput {
  margin-top: 15px;
}
