@import '~styles/vars';

.workcellLayoutTab {
  .selectTrigger,
  .input {
    width: 300px;
  }

  .inputContainer,
  .button {
    margin-top: 10px;
  }

  .select {
    margin-top: 20px;
  }

  .controls {
    margin-top: 20px;

    .controlsInner {
      display: flex;
    }

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .errorMessage {
    margin-top: 5px;
  }
}

.selectPopover {
  width: 300px;
}
