@import '~styles/vars';

@mixin colors($icon-stroke, $icon-fill, $message-color) {
  .icon {
    stroke: $icon-stroke;
    fill: $icon-fill;
  }

  .message {
    color: $message-color;
  }
}

.bigNotification {
  @include font-section-title;
  font-weight: bold;
  display: flex;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  &.success {
    @include colors(none, $success, $success);
  }

  &.warning {
    @include colors($warning, none, $warning);
  }

  &.error {
    @include colors($error, none, $error);
  }
}
