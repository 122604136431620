@import '~styles/vars';

.popoverTrigger {
  cursor: pointer;
  @include font-text-tiny;
  color: $grey-medium;
  text-decoration: underline dashed $grey-medium;
}

.noDataMessage {
  width: 200px;
  text-align: center;
  padding: 10px;
  @include font-subtext;
  color: $grey-medium;
}
.value {
  color: $grey-dark;
  @include font-subtext-bold;
}
