@import '~styles/vars';

.cultureLink {
  // TODO: Technically this is only font-weight 600, as opposed to 700 in the mock
  @include font-section-title;
}

.cultureCellLine {
  @include font-text-tiny;
  color: #999999;
}
