@import '~styles/vars';

.pageFrame {
  margin-top: 30px;
  height: 65px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
}

.goBackLabel {
  @include font-label;
  color: $grey-medium;

  &:hover {
    color: $grey-light;
  }
}

.title {
  @include font-title;
}

.subtitle {
  @include font-info;
  color: $grey-medium;
}
