@import '~styles/vars';

:global(.bp4-input-group) :global(.bp4-input) {
  @include font-subtext;
}

.label {
  @include font-label;
  color: $grey-medium;
  margin-bottom: 5px;
}

.dateInput {
  @include font-subtext;
  padding: 6px 4px 6px 8px;
  height: 34px;
  border: 1px $grey-light solid;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.placeholder {
    color: $grey-light;
  }

  &:hover {
    background-color: $off-white;
  }

  &.disabled {
    &::after {
      content: '';
      background-color: rgba(0, 0, 0, 0.05);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    color: $grey-light;
    cursor: inherit;
  }

  .value {
    flex: 1 1 0;
  }

  .icon {
    width: 16px;
    height: 16px;
    fill: $grey-medium;
    margin-right: 2px;
  }
}
