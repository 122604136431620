@import "~styles/vars";

.uploadLiveWellsStep {
  display: flex;
  margin-top: 30px;
  flex: 1 1 0;
  min-height: 0;
  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-bottom: 10px;
  }
  .inputColumn {
    width: 350px;
    margin-right: 250px;

    .textarea {
      width: 100%;
    }
    .viewAcceptedDataSchemas {
      margin-top: 10px;
    }
  }
  .previewColumn {
    flex: 1 1 0;
    min-width: 0;
  }
}
