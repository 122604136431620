@import '~styles/vars';

.liveTimeline {
  overflow: auto;
  flex: 1 1 0;
  min-height: 0;

  .controls {
    display: flex;
    margin-top: 40px;
    margin-bottom: 15px;
    width: 1175px;

    .status {
      @include font-small-info;
      margin-left: 300px;
      color: $grey-dark;

      .link {
        text-decoration: underline;
      }
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .timeControl {
      @include font-label;
      color: $grey-medium;
      margin-left: 12px;
      cursor: pointer;

      &.selected {
        @include font-label-bold;
        color: $grey-dark;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .table {
    margin-top: 25px;

    .retry {
      @include font-label-bold;
      color: $grey-dark;
      cursor: pointer;
    }
  }

  .workcellTimeline {
    display: flex;
  }

  .instruments {
    margin-top: 65px;

    .link {
      display: block;

      &:hover {
        background-color: $off-white;
      }
    }
  }
}
