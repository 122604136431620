@import "~styles/vars";

.header {
  height: 65px;

  display: flex;
  align-items: center;

  .titleContainer {
    display: flex;
    align-items: center;
    color: $grey-dark;

    .title {
      @include font-title;
    }

    .menu {
      margin-top: 9px;
    }
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }
}
