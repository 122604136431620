@import '~styles/vars';

.instrumentTile {
  display: flex;
  width: 250px;
  padding: 10px 0;
  height: 75px;
  align-items: center;

  &.mini {
    height: 55px;
    padding: 0;

    .instrumentImage {
      width: 32px;
      height: 32px;
    }

    .instrumentText {
      padding: 10px 10px 10px 0;
    }

    &.leftAligned {
      .instrumentText {
        padding: 10px 0 10px 10px;
      }
    }
  }

  &.leftAligned {
    flex-direction: row-reverse;

    .instrumentText {
      text-align: left;
      padding: 10px 0 10px 20px;
    }
  }

  .instrumentText {
    width: 200px;
    text-align: right;
    padding: 10px 20px 10px 0;

    .instrumentType {
      @include one-line;
      @include font-label;
      color: $grey-medium;
    }

    .instrumentNameWithStatus {
      @include one-line;
      width: 100%;
    }
  }

  .instrumentImage {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
}
