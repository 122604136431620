@import '~styles/vars';

.textWithOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.helpContent {
  @include font-subtext;
  width: 300px;
  padding: 20px;
  color: $grey-dark;
  word-break: normal;
  overflow-wrap: anywhere;
  max-height: 300px;
  overflow: auto;
}
