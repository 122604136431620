@import '~styles/vars';

.charts {
  display: flex;

  .label {
    @include font-label-bold;
    color: $grey-medium;
    margin-bottom: 10px;
  }
}

.plates {
  margin-top: 40px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;

  .plate {
    margin-right: 50px;
  }
}

.selection {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;

  .selectionText {
    @include font-title-bold;
    color: $accent;
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .button {
    width: 150px;
  }
}

.dataTable {
  flex: 1 1 0;
  min-height: 0;
}

.bottom {
  width: 910px;
  flex: 1 1 0;
  min-height: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
