@import '~styles/vars';

.container {
  margin: 1em;
  padding: 1em;
  align-self: stretch;
  border: 1px solid $primary-bg;
  color: $primary-bg;
  border-radius: 8px;
  @include font-label-bold;
  text-align: center;
  transition: background 0.5s, opacity 0.15s;

  &:hover {
    color: $primary-bg;

    &:not(.static) {
      opacity: 0.8;
    }
  }

  &.active {
    background: $primary-bg;
    color: $primary;
  }

  &.static {
    user-select: none;
  }
}
