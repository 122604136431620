@import '~styles/vars';

.cancelProcessStepWarning {
  .text {
    @include font-subtext;
    margin-top: 10px;
  }

  .instructionGroup {
    @include font-subtext;
    margin-left: 15px;
    margin-top: 30px;

    li {
      margin: 15px 0;
    }
  }
}
