@import '~styles/vars';

.inhecoScilaOpenDrawerDialog {
  .text {
    @include font-subtext;
    margin-top: 10px;
  }

  .openDrawerMessage {
    margin-left: 4px;
  }
}
