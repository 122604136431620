@import '~styles/vars.scss';

.inviteUserDialog {
  .title {
    @include font-section-title;
  }

  .label {
    @include font-label;
    color: $grey-medium;
    margin: 20px 0 5px;
  }

  .roleOptions {
    display: flex;

    .roleOption {
      @include font-text-tiny-bold;
      cursor: pointer;
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .roleDescription {
    margin-top: 5px;
    color: $grey-medium;
  }

  .inputContainer {
    margin-top: 20px;
  }

  .input {
    width: 100%;
  }

  .sendInviteButtonContainer {
    text-align: right;
    margin-top: 40px;
  }

  .error {
    color: $error;
    margin-bottom: 5px;
  }
}
