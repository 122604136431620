@import '~styles/vars.scss';

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.empty {
    height: auto;
    border-bottom: 1px solid $grey-lightest;

    .tabPanel {
      border-top: none;
      height: 0;
      padding: 0;
    }
  }

  .tabPanel {
    margin-top: 0;
    border-top: 1px solid $grey-lightest;
  }

  .tab {
    outline: none;
    color: $grey-dark;
    padding-bottom: 3px;

    &:hover {
      color: $grey-dark;
    }
  }

  // For animate = true. Currently disabled due to Blueprint styling bug.
  :global(.bp4-tab-indicator) {
    background-color: $accent;
    height: 4px;
  }

  // For animate = false
  :global(.bp4-tab[aria-selected='true']) {
    color: $accent;
    box-shadow: inset 0 -3px 0 $accent;
  }

  &.nested {
    padding-top: 4px;
    margin-bottom: 20px;

    .tab {
      @include font-label-bold;
      line-height: 30px;
      color: $grey-medium;
    }

    // For animate = true. Currently disabled due to Blueprint styling bug.
    :global(.bp4-tab-indicator) {
      background-color: $warning;
      height: 4px;
    }

    // For animate = false
    :global(.bp4-tab[aria-selected='true']) {
      color: $warning;
      box-shadow: inset 0 -3px 0 $warning;
    }
  }
}
