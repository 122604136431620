@import '~styles/vars';

.trigger {
  min-width: 300px;
}

.popover {
  min-width: 300px;
}

.triggerWide {
  width: 350px;
}

.popoverWide {
  width: 350px;
}
