@import '~styles/vars';

.datasets {
  display: flex;
  flex-direction: column;
  height: 100%;

  .bigMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    margin-bottom: 40px;
    color: $grey-medium;
  }

  .table {
    flex: 1 1 0;
    min-height: 0;
  }

  .subheader {
    margin-bottom: 10px;

    .subtitle {
      @include font-text;
      color: $grey-dark;
    }
  }

  .controls {
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    align-items: flex-end;

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .actionsTrigger {
      @include font-subtext;
      padding: 4px 4px 4px 14px;
      color: $grey-medium;
      border: 1px solid $grey-light;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: $off-white;
      }

      .icon {
        fill: $grey-medium;
      }
    }
  }
}
