@import '~styles/vars';

.routineDialog {
  width: 960px;

  .title {
    @include font-section-title;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 100%;

    .name,
    .parens {
      flex-shrink: 0;
    }

    .processItemsDisplay {
      min-width: 0;
    }

    .status {
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  .subtitle {
    @include font-label;
    color: $grey-medium;
  }

  .table {
    max-height: 400px;
  }
}
