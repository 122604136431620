@import '~styles/vars';

.cultureImage {
  width: 100%;
  height: 100%;

  .cultureImageInner {
    position: relative;
    width: 100%;
    height: 100%;

    .thumbnail {
      width: 100%;
      height: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 100%;
    }

    .overlayMessage {
      @include font-label-bold;
      display: flex;
      align-items: center;
      color: white;
      margin-right: 10px;

      .icon {
        width: 20px;
        height: 20px;
        fill: white;
        margin-right: 5px;
      }
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }
}
