@import "~styles/vars";

.linkLotsDataTable {
  display: flex;
  flex-direction: column;
  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
  }
  .processStepTable {
    flex: 1 1 0;
    min-height: 0;
  }

  .linkedValue {
    display: flex;
    align-items: center;

    .valueText {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .legendBox {
    width: 14px;
    height: 14px;
    margin-right: 8px;

    &.categorical_0 {
      background-color: $categorical_0;
    }
    &.categorical_1 {
      background-color: $categorical_1;
    }
    &.categorical_2 {
      background-color: $categorical_2;
    }
    &.categorical_3 {
      background-color: $categorical_3;
    }
  }
}

.loadingMessageBox {
  width: 400px;
  padding: 30px 0;
  margin-top: 30px;
}
