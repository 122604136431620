@import '~styles/vars';

.protocols {
  display: flex;
  flex-direction: column;
  height: 100%;

  .eventTrigger {
    .property {
      font-weight: bold;
    }
  }

  .scriptName {
    @include font-monospace;
  }

  .table {
    flex: 1 1 0;
    min-height: 0;
  }
}
