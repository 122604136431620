@import '~styles/vars';

.instrumentDriverTable {
  .icon {
    fill: $grey-medium;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      fill: $grey-dark;
    }
  }
}
