@import "~styles/vars";

.linkLotsPlatePreview {
  .plates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px 15px;

    .gridItem {
      width: 120px;
      .plateName {
        @include font-text-tiny-bold;
        color: $grey-dark;
        margin-top: 5px;
        word-break: break-all;
      }
    }
  }
}
