@import '~styles/vars';

.genericScheduleForm {
  .sectionTitle {
    @include font-section-title;
    color: $grey-dark;
    margin-bottom: 10px;

    display: flex;
    align-items: flex-end;

    .fill {
      flex: 1 1 0;
    }
  }

  .selectRoutineHeader {
    width: 350px;
  }

  .contents {
    margin-top: 20px;
    display: flex;
  }

  .routineParametersColumns {
    width: 350px;
    margin-right: 100px;
  }

  .routineScheduleColumn {
    width: 350px;
  }

  .futureRoutineScheduleForForm {
    width: 350px;
  }

  .selectRoutineContainer {
    margin-top: 38px;

    .scheduleTypeButtonGroup {
      margin-bottom: 25px;
    }

    .choice {
      width: 50%;
    }
  }

  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 5px;
  }
}

.samplePlateBarcodeMultiSelect {
  margin-top: 20px;
  margin-bottom: 25px;

  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 5px;
  }
}
