@import '~styles/vars';

.buttonGroup {
  display: flex;

  .choice {
    @include font-text-tiny;
    flex: 1 1 auto;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    color: $grey-dark;
    border: 1px #ccc solid;
    cursor: pointer;
    padding: 6px 8px;

    &:hover {
      background-color: $off-white;
    }

    &:not(:first-child) {
      border-left: none;
    }

    &.active {
      background-color: $accent;
      color: $white;

      &:hover {
        background-color: lighten($accent, 20%);
      }
    }
  }

  &.disabled {
    .choice {
      cursor: default;

      &:hover {
        background-color: $white;
      }

      &.active {
        background-color: $grey-medium;
        &:hover {
          background-color: $grey-medium;
        }
      }
    }
  }
}
