@import '~styles/vars';

.confirmStartWorkcellDialog {
  .text {
    @include font-subtext;
    margin-top: 10px;
  }

  .warning {
    font-weight: bold;

    .simulation {
      color: $warning;
    }

    .unknownPlates {
      color: $error;
    }
  }

  .goToTransferStations {
    cursor: pointer;
    color: $accent;
  }

  .button {
    margin-top: 10px;
    align-self: flex-start;
  }

  .options {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}

.unknownPlatesPopover {
  padding: 10px 20px;

  .plate {
    margin: 5px 0;
  }

  .uuid {
    font-weight: bold;
    color: $error;
  }
}
