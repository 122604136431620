@import '~styles/vars';

.appHeader {
  text-align: center;

  .orgName {
    @include font-label;
    color: $grey-light;
  }

  .appName {
    color: $grey-dark;

    display: flex;
    align-items: center;
    justify-content: center;

    &.hasMenu {
      margin-left: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .menu {
    height: 28px;
    margin-left: 4px;
  }

  .icon {
    fill: $grey-light;
    width: 28px;
    height: 28px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 4px;
    margin-left: 4px;

    &:hover {
      background-color: $off-white;
    }
  }

  &.mini {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;

    .appIcon {
      width: 32px;
      height: 32px;
      margin-right: 15px;
    }

    .appName {
      @include font-subtitle;

      &.hasMenu {
        margin-left: 0;

        .menu {
          margin-top: 3px;
        }
      }
    }
  }

  &.default {
    .appIcon {
      margin: 0 auto;
      width: 64px;
      height: 64px;
    }

    .appName {
      margin-bottom: 10px;
      @include font-title;
    }
  }

  &.inline {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;

    .appIcon {
      width: 32px;
      height: 32px;
      margin-right: 15px;
    }

    .appName {
      @include font-subtitle;

      &.hasMenu {
        margin-left: 0;

        .menu {
          margin-top: 3px;
        }
      }
    }
  }
}
