@import '~styles/vars';

.processItemChange {
  display: flex;
  align-items: center;

  .icon {
    fill: $grey-medium;
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }

  .text {
    .label {
      @include font-label;
      color: $grey-medium;
    }
  }
}
