@import '~styles/vars';

.cultureOverview {
  display: flex;

  .left {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-right: 100px;
  }

  .right {
    flex: 1 1 0;
    min-width: 0;
    margin-left: 20px;
  }

  .metadata {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0 20px;

    .field {
      margin-top: 25px;
      width: calc(50% - 10px);
    }

    .media__contaminated {
      color: $error;
      font-weight: bold;

      &:hover {
        color: lighten($error, 10%);
      }
    }
  }

  .cultureImages {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;

    .header {
      display: flex;
      margin-bottom: 5px;

      .fill {
        flex: 1 1 0;
      }

      .label {
        @include font-label;
        color: $grey-medium;
      }
    }

    .imageGrid {
      margin-top: 10px;
    }
  }

  .latestData {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 50px;

    .latestDataField {
      margin-top: 25px;
      width: 50%;

      .label {
        @include font-label;
        color: $grey-medium;
      }
    }

    .latestDataFieldValue {
      margin-top: 5px;
      @include font-callout;
      display: flex;
      align-items: center;

      .iconContainer {
        margin-top: -5px;
        height: 28px;
        margin-left: -9px;
      }

      .icon {
        width: 24px;
        height: 24px;
        margin-top: 5px;
      }
    }
  }

  .chartFieldTrigger {
    width: 200px;
  }

  .chartSelect {
    margin-top: 25px;
    width: 200px;
  }

  .chartContainer {
    .lineGraph {
      margin-top: 40px;
      width: 500px;
      height: 320px;
    }
  }
}

.chartFieldPopover {
  width: 200px;
}

.error {
  color: $error;
}

.fieldValue {
  color: $grey-dark;
}
