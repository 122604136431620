@import '~styles/vars';

:global(.bp4-multi-select) {
  @include font-subtext;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: none;
  cursor: pointer;

  input::placeholder {
    color: $grey-light;
  }

  &:global(.bp4-active) {
    box-shadow: none;
    -webkit-box-shadow: none;
    background: none;
    cursor: pointer;
  }

  :global(.bp4-input) {
    cursor: pointer;
  }
}

:global(.bp4-tag) {
  @include font-subtext;
  background-color: $accent;
}

.multiSelectInput {
  @include font-subtext;
  cursor: pointer;
  position: relative;
  border: 1px $grey-light solid;

  &:hover {
    background-color: $off-white;
  }

  .value {
    width: 100%;
  }

  .icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    fill: $grey-medium;
    position: absolute;
    right: 0;
    bottom: 0;
    bottom: calc(50% - 10px);
  }
}

.multiSelect {
  padding-right: 30px;
}

.option {
  padding: 6px 10px;
  cursor: pointer;
  @include font-subtext;

  &:hover {
    background-color: $off-white;
  }

  &.active {
    background-color: $grey-lightest;
  }
}

.popover {
  :global(.bp4-menu) {
    max-height: 250px;
    overflow: auto;
    padding: 5px 0;
    max-width: none;
  }

  &.noPadding {
    :global(.bp4-popover-content) {
      padding: 0;
    }
  }
}
