@import "~styles/vars";

.genericInstrumentHeader {
  .instrumentName {
    @include font-title;
    display: flex;
    align-items: center;

    .menu {
      margin-left: 5px;
      margin-top: 8px;
    }
  }

  .instrumentErrorMessage {
    display: flex;
    align-items: center;
    height: 22px;
    margin-top: 2px;

    .errorIcon {
      stroke: $error;
      width: 16px;
      height: 16px;
    }

    .errorMessage {
      @include font-text-tiny;
      color: $error;
      margin-left: 4px;
      width: 400px;
    }
  }

  .headerInner {
    display: flex;
    align-items: center;
  }
}
