@import "~styles/vars";

.sequenceValidationRunStatusView {
  width: 400px;
}

.runStatusBarContainer {
  .runStatusBarText {
    @include font-title-bold;
    color: $grey-light;
    margin-bottom: 5px;
  }
  .runStatusBar {
    background-color: $grey-lightest;
    height: 8px;
    width: 100%;
    position: relative;

    .runStatusBarInner {
      position: absolute;
      height: 8px;
      transition: width 200ms;
    }
  }

  .message {
    @include font-text-tiny-bold;
    margin-top: 6px;
  }

  &.busy {
    .runStatusBarText {
      color: $accent;
    }

    .runStatusBarInner {
      background-color: $accent;
    }

    .message {
      color: $accent;
    }
  }

  &.failed {
    .runStatusBarText {
      color: $error;
    }

    .runStatusBarInner {
      background-color: $error;
    }

    .message {
      color: $error;
    }
  }

  &.completed {
    .runStatusBarText {
      color: $success;
    }

    .runStatusBarInner {
      background-color: $success;
    }

    .message {
      color: $success;
    }
  }
}

.sequenceStepDisplay {
  margin-top: 40px;

  .currentStepJson {
    @include font-monospace;
    color: $grey-dark;
    font-size: 12px;
    margin-top: 30px;
  }

  .header {
    .label {
      @include font-label;
      color: $grey-medium;
      margin-bottom: 3px;
    }

    .name {
      color: $grey-darkest;
    }

    .stepProgress {
      @include font-small-info;
      color: $grey-dark;
      margin-top: 2px;
    }
  }
}
