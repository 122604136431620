@import '~styles/vars';

.nextActionWhen {
  @include font-small-info;
  color: #999999;
  margin-left: 6px;
}

.modifyNextAction {
  @include font-text-tiny;
  margin-top: 4px;
  color: #999999;
  cursor: pointer;
  &:hover {
    fill: $grey-dark;
    color: $grey-dark;
  }
}

.modifyNextActionIcon {
  fill: $grey-medium;
  width: 10px;
  height: 10px;
}
