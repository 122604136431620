@import '~styles/vars.scss';

.scheduleRoutineDialog {
  .title {
    @include font-section-title;
    margin-bottom: 10px;
  }

  .controls {
    margin-top: 20px;

    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .error {
    color: $error;
    margin-top: 10px;
  }

  .label {
    @include font-label;
    color: $grey-medium;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .textarea {
    resize: none;
  }

  .inputFields {
    margin: 10px 0;
  }

  .inputs {
    width: 300px;
  }

  .noParametersMessage {
    @include font-small-info;
    color: $grey-light;
    margin: 10px 0;
  }

  .repeatsInput {
    margin-top: 10px;
  }

  .qcToggle {
    @include font-text-tiny;
    color: $grey-medium;
    cursor: pointer;
  }
}
