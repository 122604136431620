@import '~styles/vars';

.metadata {
  border-top: 2px solid $grey-light;
  padding-top: 4px;

  dt {
    @include font-label;
    color: $grey-dark;
    margin: 12px 0 2px;
  }

  dd {
    @include font-subtext;

    a {
      @include font-subtext-semibold;
    }
  }
}
