@import '~styles/vars';

.searchByBarcode {
  display: flex;
  flex-direction: column;
  height: 100%;

  .routineList {
    flex: 1 1 0;
    min-height: 0;
  }

  .controls {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    .option {
      margin-left: 15px;
      color: $grey-medium;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .bigMessage {
    margin-top: 15px;
    margin-bottom: 10px;
    color: $grey-medium;
  }
}
