@import '~styles/vars';

.workflowsInstanceTable {
  display: flex;
  flex-direction: column;
  height: 100%;

  .table {
    flex: 1 1 0;
    min-height: 0;

    .icon {
      fill: $grey-medium;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        fill: $grey-dark;
      }
    }

    .culturePlate {
      @include font-subtext-bold;
    }
  }
}

.bigMessage {
  display: flex;
  justify-content: center;
  color: $grey-medium;
  margin-top: 50px;
}
