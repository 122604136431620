@import '~styles/vars';

.clePipelineView {
  margin-top: 30px;
  height: 65px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .title {
      @include font-title;
    }

    .subtitle {
      @include font-info;
      color: $grey-medium;
    }

    .fill {
      flex: 1 0 0;
      min-width: 0;
    }

    .alertMessage {
      display: flex;
      align-items: center;
      .alertIcon {
        fill: $warning;
        height: 24px;
        width: 24px;
        margin-right: 5px;
      }

      @include font-subtitle;
      font-weight: 600;
      color: $warning;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .trigger {
      width: 266px;
      margin-right: 43px;
    }
  }
}

.popover {
  width: 266px;
}
