@import '~styles/vars';

.routine {
  display: flex;

  .fill {
    min-width: 0;
    flex: 1 1 0;
  }
}

.trigger {
  min-width: 350px;
}

.popover {
  min-width: 350px;
}
