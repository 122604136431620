@import "~styles/vars";

.linkLotsDataSchemasDialog {
  width: 1200px;
  padding: 40px 50px;
  .title {
    @include font-subtitle;
    color: $grey-dark;
  }
  .subtitle {
    @include font-info;
    color: $grey-darkest;
    margin-top: 14px;
    max-width: 525px;
  }
  .options {
    margin-top: 30px;
    display: flex;

    .optionSchema {
      margin-top: 15px;
    }

    .column {
      flex: 1 1 0;
      min-width: none;
      &:not(:last-child) {
        margin-right: 50px;
      }
      .optionTitle {
        @include font-section-title;
      }
    }
  }
}
