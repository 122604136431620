@import '~styles/vars';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.primaryMessage {
  @include font-text;
  max-width: 450px;
  margin: 2em;
  text-wrap: balance;
}

.secondaryMessage {
  @include font-subtext;
  max-width: 450px;
  margin: 5em 2em 2em;
  color: $grey-dark;
  text-wrap: balance;

  a {
    color: $accent;

    &:hover {
      color: lighten($accent, 20%);
    }
  }
}
