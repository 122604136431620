@import '~styles/vars';

.breadcrumbs {
  @include font-text;
  height: 52px;
  margin-bottom: 12px;
  border-bottom: 1px solid $grey-lightest;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: $grey-dark;

  a:hover {
    color: black;
  }
}

.separator {
  height: 10px;
  margin: 0 12px;
  fill: $grey-medium;
}

.active {
  color: black;
}
