@import '~styles/vars';

.compass {
  --center-size: 16px;
  --arrow-size: 13px;

  display: grid;
  grid-template-columns: 0 var(--center-size) 0;
  grid-template-rows: 0 var(--center-size) 0;
  grid-template-areas:
    'empty1 north  empty2'
    'west   empty3 east'
    'empty4 south  empty5';
  gap: 1px;

  &.horizontal {
    grid-template-columns: var(--arrow-size) var(--center-size) var(--arrow-size);
  }

  &.vertical {
    grid-template-rows: var(--arrow-size) var(--center-size) var(--arrow-size);
  }
}

.indicator {
  display: flex;
  align-items: center;
  cursor: pointer;

  .arrow {
    fill: $grey-light;
  }

  svg {
    flex: 0 0 auto;
  }

  &.disabled {
    cursor: default;

    .arrow {
      fill: $grey-lightest;
    }
  }

  &:not(.disabled):has(+ .interactionTarget:hover) {
    .arrow {
      fill: $grey-dark;
    }

    .label {
      display: inline;
    }
  }
}

.north,
.south {
  flex-direction: column;
}

.north {
  grid-area: north;
  align-self: end;
}

.south {
  grid-area: south;
}

.east {
  grid-area: east;
}

.west {
  grid-area: west;
  justify-self: end;
}

.label {
  @include font-small-info;
  padding: 1px 4px 0 3px;
  display: none;
  color: $grey-dark;
  user-select: none;
}

.interactionTarget {
  place-self: center;
  width: 26px;
  height: 26px;
  opacity: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  &.north {
    transform: translateY(-3px) rotate(45deg);
    border-bottom-right-radius: 6px;
  }

  &.south {
    transform: translateY(3px) rotate(45deg);
    border-top-left-radius: 6px;
  }

  &.east {
    transform: translateX(3px) rotate(45deg);
    border-bottom-left-radius: 6px;
  }

  &.west {
    transform: translateX(-3px) rotate(45deg);
    border-top-right-radius: 6px;
  }

  &.disabled {
    cursor: default;
  }
}
