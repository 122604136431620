@import '~styles/vars';

.warningNotification {
  margin-top: 10px;
}

.toolContainer {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  .tool {
    padding: 10px 20px 10px 0;
  }
}
