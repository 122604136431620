@import '~styles/vars';

.needsAttention {
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 12px;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    color: $grey-dark;

    .title {
      @include font-title;
    }

    .menu {
      margin-top: 7px;
    }
  }

  .title {
    @include font-title;
    color: $grey-dark;
  }

  .titleInner {
    display: inline;
  }

  .goBackLabel {
    @include font-label;
    color: $grey-medium;

    &:hover {
      color: $grey-light;
    }
  }

  .filterLabel {
    @include font-label;
    margin-top: 12px;
    color: #666666;
  }

  .tableContainer {
    margin-top: 20px;
    display: flex;
    flex: 1 1 0;
    width: 900px;

    .table {
      // flushLeft adds 10px
      width: 910px;
    }
  }

  .cultureLink {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .lineGraph {
    height: 50px;
    width: 70px;
  }

  .issues {
    .issueLabel {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .thumbnail {
    width: 85px;
    height: 55px;
  }

  .confluencyText {
    @include font-text;
  }

  .bulkUpdateTrigger {
    @include font-subtext;
    width: 200px;
    padding: 6px 4px 6px 20px;
    box-shadow: $box-shadow-lite;
    color: $grey-medium;
    border: 1px solid $grey-light;
    background-color: white;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.disabled {
      color: $grey-light;
      background-color: $grey-lightest;
      border: 1px solid $grey-lightest;
      box-shadow: none;
    }

    &:hover:not(.disabled) {
      background-color: $off-white;
    }
  }

  .needsAttentionFilters {
    display: flex;
    width: 900px;
    align-items: flex-end;

    .spacer {
      flex: 1 1 0;
      min-width: 0;
    }
  }
}

.bulkUpdateMenu {
  width: 200px;
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .issueLabel {
    margin-bottom: 8px;
  }
}

.dialogIssue {
  @include font-text;
  font-weight: 700;
}

.dialogNav {
  @include font-subtext;
  color: #999999;
  margin-bottom: 32px;
}

.navText {
  margin: 0 14px;
}

.dialogIcon {
  fill: #999999;
  cursor: pointer;
}

.dialogActions {
  display: flex;
  justify-content: space-between;
}

.dialogButton {
  width: 335px;
}
