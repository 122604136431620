@import '~styles/vars.scss';

.connectSlackDialog {
  .title {
    @include font-section-title;
  }

  .inputContainer {
    margin-top: 20px;
  }

  .input {
    width: 100%;
  }


  .controls {
    margin-top: 20px;
    .button {
      margin-left: 10px;
    }
  }
}
