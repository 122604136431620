@import "~styles/vars";

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(var(--plate-columns), 1fr);
  grid-template-rows: repeat(var(--plate-rows), 1fr);
  gap: 8px;
}

.well {
  aspect-ratio: 1 / 1;

  border: 1px solid $grey-lightest;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &:has(> .linkToCulture:hover) .imagery {
    background-color: $accent-bg;
  }

  .selectableLayer {
    &:hover {
      border: 3px solid rgba($accent-dark, 0.5);
      cursor: pointer;
    }
  }

  .selectedLayer {
    display: grid;
    background-color: rgba($accent, 0.3);
    place-content: center;

    &:hover {
      border: 3px solid rgba($accent-dark, 0.3);
      cursor: pointer;
    }

    .checkmarkIcon {
      fill: white;
      width: 32px;
      height: 32px;
    }
  }

  .loadingPlaceholder & {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  }

  .layer {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}

.imagery {
  padding: 7px;

  display: grid;
  grid-template-columns: repeat(var(--montage-columns), 1fr);
  grid-template-rows: repeat(var(--montage-rows), 1fr);
  gap: 2px;

  .site {
    aspect-ratio: 1 / 1;

    border-radius: 3px;
    background-image: image-set(var(--sprite-url-2x) 2x);
    background-size: calc(100% * var(--num-sites-per-montage))
      calc(100% * var(--sprite-num-wells));
    background-position-y: calc(
      100% * (var(--sprite-well-index) / (var(--sprite-num-wells) - 1))
    );
    background-position-x: calc(
      100% * (var(--site-index) / (var(--num-sites-per-montage) - 1))
    );
    background-repeat: no-repeat; // Reduces confusion when debugging.
    background-color: $grey-lightest; // Empty state and loading skeleton.
    animation: 2s linear 1s infinite alternate backgroundColorPulse;
  }
}

@keyframes backgroundColorPulse {
  from {
    background-color: $grey-lightest;
  }
  to {
    background-color: $off-white;
  }
}

.notImaged {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background-color: $off-white;

  .icon {
    fill: $grey-medium;
    width: 20px;
  }

  .iconTooltip {
    @include font-label;
    text-align: center;
    color: $grey-medium;

    .format96 & {
      display: none;
    }
  }
}

.linkToCulture {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  z-index: 1;

  &:hover {
    border: 3px solid $accent-dark;
  }
}

.format96 {
  .imagery {
    padding: 0;
  }

  .well:has(> .linkToCulture:hover) {
    .imagery {
      padding: 0;
    }

    .linkToCulture {
      background-color: transparentize($accent-light, 0.6);
    }
  }
}
