@import "~styles/vars";

.sequenceFileInlineDialog {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey-lightest;
  box-shadow: $box-shadow;
  .header {
    padding: 25px;
    display: flex;

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .label {
    @include font-label;
    color: $grey-medium;
  }

  .seqFileName {
    @include font-subtext-bold;
    color: black;
    margin-top: 3px;
  }

  .seqFileContent {
    @include font-monospace;
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
    padding: 0 25px 25px;
    color: $grey-dark;
    font-size: 12px;
  }

  .seqFilePath {
    @include font-small-info;
    color: $grey-medium;
    text-decoration: underline dashed $grey-medium;
    cursor: pointer;
    margin-top: 2px;
  }

  .closeIcon {
    width: 32px;
    height: 32px;
    margin-top: 2px;
    fill: $grey-medium;
    cursor: pointer;

    &:hover {
      fill: $grey-light;
    }
  }
}

.seqFilePathPopover {
  @include font-text-tiny;
  max-width: 500px;
  padding: 20px;
  word-wrap: break-word;
  color: $grey-dark;
}
