@import '~styles/vars';

.instrumentStatusCallout {
  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 2px;
  }
  .callout {
    @include font-callout;
    color: $accent;
  }
}
