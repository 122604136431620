@import '~styles/vars';

.loadUnloadItemsBody {
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;

    .inner {
      display: flex;
      padding: 10px 0 20px;

      .form {
        width: 350px;
        margin-right: 75px;
      }

      .terminateWorkflowsForm {
        width: 350px;
        margin-left: 75px;
      }

      .locationSelection {
        width: 380px;
      }
    }
  }

  .error {
    margin-top: 20px;
    width: 800px;
  }

  .typeSelectContainer {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .typeSelectTrigger {
    width: 350px;
  }
}

.popoverSelectTrigger {
  width: 350px;
}

.loadUnloadOptions {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .choice {
    width: 80px;
  }
}

.failedUnloadUserWarning {
  margin-top: 10px;
  width: 800px;
}
