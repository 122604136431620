@import "~styles/vars";

.instrumentStatusTile {
  width: 380px;
  height: 400px;
  border: 1px solid $grey-lightest;
  box-shadow: $box-shadow;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: $off-white;
  }

  .header {
    display: flex;

    .fill {
      flex: 1 1 0;
    }
  }

  .body {
    margin-left: 60px;
    margin-top: 20px;
    margin-bottom: 10px;
    flex: 1 1 0;
    min-height: 0;
    overflow: auto;
  }

  .footer {
    margin-left: 60px;
  }

  &.isBusy {
    border: 4px solid $accent;
  }

  .label {
    @include font-label;
    color: $grey-medium;
    margin-bottom: 10px;
  }

  .storageLabel {
    margin-top: 20px;
  }
}
