@import '~styles/vars.scss';

.checkbox {
  &:global(.bp4-control) {
    margin-bottom: 0;
    padding-left: 0;

    :global(.bp4-control-indicator) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    input:checked ~ :global(.bp4-control-indicator) {
      background-color: $accent;
    }

    &:global(.bp4-disabled) {
      input:checked ~ :global(.bp4-control-indicator) {
        background-color: $grey-medium;
      }
    }
  }
}
