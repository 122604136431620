@import "~styles/vars";

.genericInstrumentPage {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .body {
    flex: 1 1 0;
    min-height: 0;
    display: flex;
    height: 100%;

    .leftSidebar {
      width: 250px;
    }

    .bodyContents {
      margin-left: 100px;
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .controlBox {
    margin-top: 40px;
  }
}
